var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: [
                        (v) => !!v || _vm.$t("error.nameRequired"),
                        (v) => v.length >= 4 || _vm.$t("error.nameLength"),
                      ],
                      label: _vm.$t("label.name"),
                    },
                    model: {
                      value: _vm.group.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.group, "name", $$v)
                      },
                      expression: "group.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-autocomplete", {
                    attrs: {
                      "no-data-text": _vm.$t("error.noData"),
                      items: _vm.availableGroups,
                      "item-text": "label",
                      "item-value": "value",
                      label: _vm.$t("label.parent"),
                      dense: "",
                    },
                    model: {
                      value: _vm.group.parent,
                      callback: function ($$v) {
                        _vm.$set(_vm.group, "parent", $$v)
                      },
                      expression: "group.parent",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-autocomplete", {
                    attrs: {
                      rules: [(v) => !!v || _vm.$t("error.rightRequired")],
                      items: _vm.availableRights,
                      "item-text": "label",
                      "item-value": "value",
                      label: _vm.$t("label.access"),
                    },
                    model: {
                      value: _vm.group.accessRight,
                      callback: function ($$v) {
                        _vm.$set(_vm.group, "accessRight", $$v)
                      },
                      expression: "group.accessRight",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("action.create")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }