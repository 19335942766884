/* eslint-disable import/first */
import VueRouter from 'vue-router';
import AppStore from '@/stores/AppStore';
import appConfig from '@/appConfig';
import Vue from 'vue';

const routes = Object.assign([], { byPath: {} });
const addRoute = (path, component, params = {}) => {
  const route = { path };
  if (component != null) {
    route.name = component.name;
    route.component = component;
  }
  Object.assign(route, { requiresAuth: true, props: true }, params);

  routes.push(route);
  routes.byPath[route.path] = route;
  return route;
};


// -- core routes ----

addRoute('/', null, { redirect: '/projects' });

import LoginScreen from '@/_components/_core/LoginScreen';
addRoute('/login', LoginScreen, { requiresAuth: false });

import GitlabOauthScreen from '@/_components/_core/GitlabOauthScreen';
addRoute('/gitlab-oauth', GitlabOauthScreen, { requiresAuth: false });

import AppInfo from '@/_components/_core/AppInfo';
addRoute('/app-info', AppInfo, { requiresAuth: false });


// -- project routes ------

import ProjectListScreen from '@/_components/projects/ProjectListScreen';
addRoute('/projects', ProjectListScreen);

import ArchiveListScreen from '@/_components/projects/ArchiveListScreen';
addRoute('/archives', ArchiveListScreen);

import ProjectCreationScreen from '@/_components/projects/ProjectCreationScreen';
addRoute('/projects/new', ProjectCreationScreen);

import ProjectDetailsScreen from '@/_components/projects/ProjectDetailsScreen';
addRoute('/projects/:projectName', ProjectDetailsScreen);

import ProjectSettingsScreen from '@/_components/projects/ProjectSettingsScreen';
addRoute('/projects/:projectName/settings', ProjectSettingsScreen);

import GitlabProjectListScreen from '@/_components/projects/GitlabProjectListScreen';
addRoute('/gitlab-projects', GitlabProjectListScreen);

import ISOListScreen from '@/_components/isos/ISOListScreen';
addRoute('/ISOs', ISOListScreen);


// -- build/device/group routes ------

import BuildListScreen from '@/_components/builds/BuildListScreen';
addRoute('/projects/:projectName/builds', BuildListScreen);

import DeviceListScreen from '@/_components/devices/DeviceListScreen';
addRoute('/projects/:projectName/devices', DeviceListScreen);

import DeviceDetailsScreen from '@/_components/devices/DeviceDetailsScreen';
addRoute('/projects/:projectName/devices/:deviceId', DeviceDetailsScreen);

import GroupListScreen from '@/_components/device-groups/GroupListScreen';
addRoute('/projects/:projectName/groups', GroupListScreen);

import GroupCreationScreen from '@/_components/device-groups/GroupCreationScreen';
addRoute('/projects/:projectName/groups/new', GroupCreationScreen);

import GroupDetailsScreen from '@/_components/device-groups/GroupDetailsScreen';
addRoute('/projects/:projectName/groups/:groupName', GroupDetailsScreen);


// -- metric routes ------

import MetricListScreen from '@/_components/metrics/MetricListScreen';
addRoute('/projects/:projectName/metrics', MetricListScreen, {
  beforeEnter() {
    location.href = 'https://arcadia.sarbakan.com/metabase';
  },
});

import LogsListScreen from '@/_components/logs/LogsListScreen';
addRoute('/logs', LogsListScreen);

// -- 404 (must be at the end) ----

import NotFoundScreen from '@/_components/_core/NotFoundScreen';
addRoute('*', NotFoundScreen, { requiresAuth: false });

// -- router ------

Vue.use(VueRouter);

const router = new VueRouter({ mode: 'history', routes });
router.beforeEach((to, from, next) => {
  const route = getRoute(to);
  if ((!route || route.requiresAuth) && !AppStore.getters.isAuthenticated) {
    next('login');
    return;
  }
  next();
});


// -- utils ------

function getRoute(v) {
  if (v == null || routes.includes(v)) {
    return v;
  }
  if (typeof v === 'string') {
    const field = v.startsWith('/') ? 'path' : 'name';
    return routes.find(r => r[field] === v);
  }
  if (v.matched && v.matched.length) {
    return routes.byPath[v.matched[0].path];
  }
  throw new Error('Route not found: ' + v);
}

function getHref(v, params, opt) {
  const route = getRoute(v);

  const path = route.path.replace(/:([a-zA-Z0-9_]+)/g, (meh, paramName) => {
    if (!params || params[paramName] == null) {
      throw new Error('Param missing: ' + paramName);
    }
    return params[paramName];
  });

  let base = '';
  if (opt && opt.abs) {
    const publicAddress = new URL(appConfig.server.publicAddress);
    if (publicAddress.host === 'localhost') publicAddress.port = appConfig.server.port;
    base = publicAddress.toString().replace(/\/$/, '');
  }

  return base + path;
}

Vue.filter('routeToHref', (v, params, opt) => getHref(v, params, opt));


export { router, routes, getRoute, getHref };
