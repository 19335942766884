import i18nEn from '@/assets/i18n/en';
import i18nFr from '@/assets/i18n/fr';
import session from './session';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

Vue.use(VueI18n);

export default new VueI18n({
  locale: session.getUserPref('locale'),
  messages: { en: i18nEn, fr: i18nFr },
});
