var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: false } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-card-title",
                [
                  _vm._v("\n        Logs\n        "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "elevation-1",
                staticStyle: { width: "100%" },
                attrs: {
                  dense: "",
                  headers: [
                    { text: _vm.$t("label.date"), value: "ts" },
                    { text: _vm.$t("label.name"), value: "name" },
                    { text: _vm.$t("label.endpoint"), value: "endpoint" },
                    { text: _vm.$t("label.params"), value: "params" },
                  ],
                  search: _vm.search,
                  items: _vm.logs,
                  "hide-actions": true,
                  loading: _vm.loading(),
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c(
                          "tr",
                          { attrs: { slot: "activator" }, slot: "activator" },
                          [
                            _c("td", [
                              _vm._v(_vm._s(_vm.timeConverter(props.item.ts))),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.endpoint))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.params))]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }