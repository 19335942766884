export default {
  // active project & related data
  SET_ACTIVE_PROJECT: 'SET_ACTIVE_PROJECT',
  LOAD_BUILDS: 'LOAD_BUILDS',
  LOAD_DEVICES: 'LOAD_DEVICES',
  UNLOAD_DEVICES: 'UNLOAD_DEVICES',
  LOAD_GROUPS: 'LOAD_GROUPS',
  UNLOAD_GROUPS: 'UNLOAD_GROUPS',

  // edits
  MAKE_BUILD_PERMANENT: 'MAKE_BUILD_PERMANENT',
  SAVE_GROUP_DEPLOY_CONFIG: 'SAVE_GROUP_DEPLOY_CONFIG',
  SAVE_GROUP_CONFIG: 'SAVE_GROUP_CONFIG',
  DELETE_GROUP: 'DELETE_GROUP',

  // gl projects
  LOAD_GL_PROJECTS: 'LOAD_GL_PROJECTS',

  // logs
  LOAD_LOGS: 'LOAD_LOGS',

  // auth & prefs
  LOGIN: 'LOGIN',
  RENEW_TOKENS: 'RENEW_TOKENS',
  SETUP_KEEPALIVE: 'SETUP_KEEPALIVE',
};
