var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-btn",
                { attrs: { href: `/projects/${this.projectName}/groups/new` } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("action.create")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "elevation-1 wide-table",
                attrs: {
                  headers: [
                    { text: _vm.$t("label.name"), value: "name" },
                    { text: _vm.$t("label.targetBuild"), value: "targetBuild" },
                    { text: _vm.$t("label.parent"), value: "parent" },
                    { text: _vm.$t("label.config"), value: "config" },
                    { text: _vm.$t("label.nbDevice"), value: "config" },
                  ],
                  items: _vm.groups,
                  "hide-actions": true,
                  loading: _vm.loading,
                  "rows-per-page-text": _vm.$t("label.rowsPerPage"),
                  "no-data-text": _vm.$t("label.noData"),
                  "no-results-text": _vm.$t("label.noResult"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "pageText",
                    fn: function (props) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("label.pagination", props)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c(
                          "tr",
                          {
                            staticClass: "clickable",
                            attrs: { slot: "activator" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoGroupDetails(props.item.name)
                              },
                            },
                            slot: "activator",
                          },
                          [
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.targetBuild))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.getGroupName(props.item.parent))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  JSON.stringify(props.item.configs, null, 1)
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.getNumberOfDevices(props.item._id) +
                                    " " +
                                    _vm.$t("label.devicesInGroup")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }