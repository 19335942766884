var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: false } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      "no-data-text": _vm.$t("error.noData"),
                      items: _vm.gitlabProjects,
                      loading: _vm.gitlabProjects.length == 0,
                      "item-text": "name",
                      "item-value": "id",
                      label: _vm.$t("label.gitlabProject"),
                      dense: "",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.project.gitlabProject,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "gitlabProject", $$v)
                      },
                      expression: "project.gitlabProject",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      rules: [
                        (v) => !!v || _vm.$t("error.descriptionRequired"),
                        (v) =>
                          v.length >= 4 || _vm.$t("error.descriptionLength"),
                      ],
                      label: _vm.$t("label.description"),
                    },
                    model: {
                      value: _vm.project.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "description", $$v)
                      },
                      expression: "project.description",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("action.create")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }