var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "completeTemplate" } },
    [
      _c(
        "arcadia-ui",
        { attrs: { sidebar: false } },
        [
          _c(
            "v-content",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.projectToShow,
                          expression: "projectToShow",
                        },
                      ],
                      staticClass: "custom-select",
                      staticStyle: {
                        width: "150px",
                        margin: "0px 10px 10px 20px",
                      },
                      attrs: { id: "projectList" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.projectToShow = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.sortISOs()
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.projects, function (choice) {
                      return _c("option", { key: choice }, [
                        _vm._v(_vm._s(choice)),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.showArchive,
                        expression: "showArchive",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "showArchive" },
                    domProps: {
                      checked: Array.isArray(_vm.showArchive)
                        ? _vm._i(_vm.showArchive, null) > -1
                        : _vm.showArchive,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.showArchive,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.showArchive = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.showArchive = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.showArchive = $$c
                          }
                        },
                        function ($event) {
                          return _vm.sortISOs()
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "showArchive" },
                    },
                    [_vm._v(_vm._s(_vm.$t("message.showArchive")))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ISOsToFind,
                        expression: "ISOsToFind",
                      },
                    ],
                    ref: "showISOsInput",
                    staticClass: "custom-select",
                    attrs: { type: "text", id: "showISOs" },
                    domProps: { value: _vm.ISOsToFind },
                    on: {
                      ":focus": _vm.onFocus,
                      change: function ($event) {
                        return _vm.sortISOs()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.ISOsToFind = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    staticStyle: { width: "100%" },
                    attrs: {
                      headers: [
                        { sortable: false },
                        { text: _vm.$t("label.name"), value: "IsoId" },
                        { sortable: false },
                        { text: _vm.$t("label.project"), value: "projectName" },
                        { sortable: false },
                      ],
                      items: _vm.VisibleISOs,
                      "hide-actions": true,
                      "no-data-text": _vm.$t("label.noData"),
                      "no-results-text": _vm.$t("label.noResult"),
                      id: "iso",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function (props) {
                          return [
                            _c(
                              "tr",
                              {
                                staticClass: "clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.expandIso($event, props.item)
                                  },
                                },
                              },
                              [
                                _c("td", [
                                  _c("img", {
                                    attrs: {
                                      width: "30px",
                                      height: "30px",
                                      src: props.item.avatar || null,
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(props.item.IsoId))]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "clickable",
                                      attrs: { target: "_blank" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectISO(
                                            $event,
                                            props.item
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("get_app")])],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { attrs: { "text-align": "left" } }, [
                                  _vm._v(_vm._s(props.item.projectName)),
                                ]),
                                _vm._v(" "),
                                _c("td"),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "tr",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: props.item.shouldShow,
                                    expression: "props.item.shouldShow",
                                  },
                                ],
                                staticClass: "infos",
                                attrs: { id: "infos" },
                              },
                              [
                                _c("td", { attrs: { colspan: "5" } }, [
                                  _c(
                                    "ul",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                props.item.hardDrive !== "",
                                              expression:
                                                'props.item.hardDrive!==""',
                                            },
                                          ],
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("label.hardDrive")
                                              ) + ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(props.item.hardDrive)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                props.item.motherboard !== "",
                                              expression:
                                                'props.item.motherboard!==""',
                                            },
                                          ],
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("label.motherboard")
                                              ) + ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(props.item.motherboard)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                props.item.graphicCard !== "",
                                              expression:
                                                'props.item.graphicCard!==""',
                                            },
                                          ],
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("label.graphicCard")
                                              ) + ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(props.item.graphicCard)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                props.item.processor !== "",
                                              expression:
                                                'props.item.processor!==""',
                                            },
                                          ],
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("label.processor")
                                              ) + ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(props.item.processor)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: props.item.version !== "",
                                              expression:
                                                'props.item.version!==""',
                                            },
                                          ],
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(_vm.$t("label.version")) +
                                                ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("label", [
                                            _vm._v(_vm._s(props.item.version)),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                props.item.buildNumber !== "",
                                              expression:
                                                'props.item.buildNumber!==""',
                                            },
                                          ],
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(_vm.$t("label.build")) +
                                                ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(props.item.buildNumber)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("label.date")) + ":"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.getDate(props.item.date))
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }