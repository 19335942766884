export default {
  action: {
    archive: `Archiver`,
    collapseSidebar: `Réduire la barre`,
    commit: `Confirmer`,
    create: `Créer`,
    downloadBuild: `Télécharger le build`,
    edit: `Modifier`,
    exportExcel: `Exporter pour Excel`,
    filter: `Filtrer`,
    login: `Identifier`,
    loginGitlab: `S'identifier avec Gitlab`,
    logout: `Déconnecter`,
    remove: `Supprimer`,
    reset: `Réinitialiser`,
    restore: `Restaurer`,
    return: `Retourner`,
    save: `Sauvegarder`,
    saveSchema: `Sauvegarder le schéma`,
    used: `En cours d'utilisation`,
    uploaded: `Téléversé`,
    uploadLive: `Téléverser le build en Direct`,
  },
  label: {
    access: `Droits`,
    all: `Tous`,
    artifacts: `Artefacts`,
    branch: `Branche`,
    build: `Build`,
    children: `Enfants`,
    commit: `Commit`,
    config: `Config`,
    creationDate: `Date de création`,
    data: `Données`,
    date: `Date`,
    dateFrom: `Date Depuis`,
    dateTo: `Date À`,
    description: `Description`,
    details: `Détail`,
    deviceId: `Id de l'appareil`,
    devices: `Appareils`,
    devicesInGroup: `Appareils dans le groupe`,
    endpoint: `Requête`,
    event: `Événement`,
    events: `Événements`,
    filename: `Nom de fichier`,
    forkedFrom: `Bifurqué à partir de`,
    gitlabProject: `Projet Gitlab`,
    graphicCard: `Carte graphique`,
    gridView: `Grille`,
    group: `Groupe`,
    groups: `Groupes`,
    hardDrive: `Disque dur`,
    id: `Id`,
    job: `Tâche`,
    key: `Clé`,
    lastUpdate: `Dernière vérification de mise à jour`,
    material: `Matériel`,
    metaData: `Meta données`,
    metric: `Envoie de métriques`,
    metricsForProject: `Metriques pour ce projet`,
    metricsUrl: `Url des Métriques`,
    motherboard: `Carte mère`,
    name: `Nom`,
    nbDevice: `Nombre d'appareil`,
    no: `Non`,
    noData: `Aucune donnée disponible`,
    noResult: `Aucun résultat trouvé`,
    pagination: `{pageStart} - {pageStop} de {itemsLength}`,
    params: `Paramètre`,
    parent: `Parent`,
    password: `Mot de passe`,
    processor: `Processeur`,
    project: `Projet`,
    rowsPerPage: `Lignes par page`,
    sessionId: `Id de session`,
    size: `Taille`,
    status: `Statut`,
    stayConnected: `Rester Connecté`,
    targetBuild: `Build Cible`,
    timestamp: `Horodatage`,
    treeView: `Arborescence`,
    user: `Utilisateur`,
    username: `Nom d'utilisateur`,
    version: `Version`,
    yes: `Oui`,
  },
  message: {
    confirmMetricOff: `Vous allez faire en sorte que toutes les bornes d'arcade de ce groupe n'enverront plus de métriques. Si vous continuez,
    un courriel sera envoyé au lead pour l'avertir.`,
    confirmArchive: `Le projet sera tansféré dans la section archive, il sera toujours possible de le déarchiver.`,
    confirmDelete: `!!!!ATTENTION!!!! Vous allez supprimer le groupe de façon permanante! cette action est impossible à réverser!`,
    confirmDeleteDevice: `!!!!ATTENTION!!!! Vous allez supprimer l'appareil de façon permanante! cette action est impossible à réverser!`,
    confirmDowngrade: `Vous allez passé à une version précédente de ce projet pour ce groupe. Voulez-vous continuer?`,
    gitlabRedirection: `Vous allez être redirigé vers Gitlab pour l'authentification`,
    nothingToChange: `Rien à changer`,
    somethingNotRight: `Quelquechose n'est pas dans l'ordre`,
    validateChanges: `Veuillez valider vos changements`,
    showArchive: `Afficher les archives`,
  },
  title: {
    app: `Arcadia`,
    archive: `Archive`,
    confirm: `Êtes-vous certains de vouloir faire cette action?`,
    // root nav
    projects: `Projets`,
    newProject: `Nouveau Projet`,
    gitlabProjects: `Projets Gitlab`,
    ISOs: `ISOs`,
    // project nav
    projectDetails: `Détails du projet`,
    projectBuilds: `Builds`,
    projectDevices: `Appareils`,
    projectGroups: `Groupes`,
    projectMetrics: `Métriques`,
    projectSettings: `Paramètres`,
  },
  error: {
    badAuhth: `Mauvaise combinaison utilisateur / mot de passe`,
    descriptionRequired: `La description est requise`,
    descriptionLength: `La description doit avoir au moins 4 caractères`,
    duplicated: `Cet élément existe déjà`,
    genericRequestError: `Les données que vous avez envoyer sont incorrect ou les validation serveur sont trop stricte`,
    nameRequired: `Le nom est requis`,
    nameLength: `Le nom doit avoir au moins 4 caractères`,
    noData: `Aucune donnée disponible`,
    rightRequired: `Les droits sont requis`,
    sessionExpired: `Session expirée`,
  },
};
