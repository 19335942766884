<style>
img { border-radius: 50%; }
.custom-select {
  position: relative;
  font-family: Arial,serif;
  background-color: white;
  appearance: auto;
  border: 2px solid #333333;
}
</style>
<template>
  <div id="completeTemplate">
    <arcadia-ui :sidebar="false">
      <v-content>
        <v-container fluid>
          <select class="custom-select" id="projectList" style="width:150px;margin: 0px 10px 10px 20px" @change="sortISOs()" v-model="projectToShow">
            <option v-for="choice in projects" :key="choice">{{ choice }}</option>
          </select>

          <input type="checkbox" v-model="showArchive" id="showArchive" class="form-check-input" @change="sortISOs()" />
          <label class="form-check-label" for="showArchive">{{ $t('message.showArchive') }}</label>

          <input class="custom-select" type="text" v-model="ISOsToFind" @:focus="onFocus" id="showISOs" @change="sortISOs()" ref="showISOsInput" />

          <v-data-table
            :headers="[
              { sortable: false },
              { text: $t('label.name'), value: 'IsoId' },
              { sortable: false },
              { text: $t('label.project'), value: 'projectName' },
              { sortable: false },
            ]"
            :items="VisibleISOs"
            :hide-actions="true"
            :no-data-text="$t('label.noData')"
            :no-results-text="$t('label.noResult')"
            class="elevation-1" style="width: 100%"
            id="iso">
            <template slot="items" slot-scope="props">
              <tr class="clickable" @click="expandIso($event, props.item)">
                <td><img width="30px" height="30px" :src="props.item.avatar || null" /></td> <!--Project avatar-->
                <td>{{props.item.IsoId}}</td> <!--Iso number-->
                <td>
                  <a class="clickable" target="_blank" @click="selectISO($event, props.item)"> <!--Start ISOs software-->
                    <v-icon>get_app</v-icon>
                  </a>
                </td>
                <td text-align="left">{{props.item.projectName}}</td> <!--Project name-->
                <td>
                  <!--<a class="clickable" target="_blank" @click="openGitlabURL($event, 'a004a-adrenaline-twister')">
                    <v-icon>open_in_new</v-icon>
                  </a>-->
                </td>
              </tr>
              <tr id="infos" class="infos" v-show="props.item.shouldShow">
                <td colspan="5">
                  <ul style="text-align: left">
                    <li v-show="props.item.hardDrive!==&quot;&quot;">
                      <label>{{$t('label.hardDrive')}}:</label> <!--Material-->
                      <label>{{props.item.hardDrive}}</label>
                    </li>
                    <li v-show="props.item.motherboard!==&quot;&quot;">
                      <label>{{$t('label.motherboard')}}:</label> <!--Material-->
                      <label>{{props.item.motherboard}}</label>
                    </li>
                    <li v-show="props.item.graphicCard!==&quot;&quot;">
                      <label>{{$t('label.graphicCard')}}:</label> <!--Material-->
                      <label>{{props.item.graphicCard}}</label>
                    </li>
                    <li v-show="props.item.processor!==&quot;&quot;">
                      <label>{{$t('label.processor')}}:</label> <!--Material-->
                      <label>{{props.item.processor}}</label>
                    </li>
                    <li v-show="props.item.version!==&quot;&quot;">
                      <label>{{$t('label.version')}}:</label> <!--Number version-->
                      <label>{{props.item.version}}</label>
                    </li>
                    <li v-show="props.item.buildNumber!==&quot;&quot;">
                      <label>{{$t('label.build')}}:</label> <!--Build number-->
                      <label>{{props.item.buildNumber}}</label>
                    </li>
                    <li>
                      <label>{{$t('label.date')}}:</label> <!--ISO date-->
                      <label>{{getDate(props.item.date)}}</label>
                    </li>
                  </ul>
                </td>
              </tr>
            </template>
          </v-data-table>

        </v-container>
      </v-content>
    </arcadia-ui>
  </div>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaBackend from '@/externals/ArcadiaBackend';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import session from '@/session.js';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';
import i18n from '@/i18n';

export default {
  name: filepathToComponentName(__filename),
  data: () => ({
    ISOs: [],
    VisibleISOs: [],
    projects: [''],
    shows: [],
    index: 0,
    indexShow: 0,
    showArchive: false,
    ISOsToFind: '',
    projectToShow: i18n.t('label.all'),
  }),
  methods: {
    sortISOs() {
      let iso;
      this.VisibleISOs = [];
      for (let i = 0; i < this.ISOs.length; i++) {
        iso = this.ISOs[i];
        if (iso.archive === this.showArchive && (this.projectToShow === i18n.t('label.all') ||
          iso.projectName === this.projectToShow) && iso.IsoId.includes(this.ISOsToFind)) {
          this.VisibleISOs.push(iso);
        }
      }
      this.focusInput();
    },
    focusInput() {
      this.$refs.showISOsInput.focus();
    },
    onFocus() {
      this.$refs.showISOsInput.select();
    },
    loadData() {
      this.projects = [];
      this.projects.push(i18n.t('label.all'));
      ArcadiaBackend.ISO.getAllISOs()
        .then(body => {
          this.ISOs = body;
          this.shows = [this.ISOs.length];
          let project = '';
          for (let i = 0; i < this.ISOs.length; i++) {
            project = this.ISOs[i].projectName;
            if (!this.projects.includes(project)) {
              this.projects.push(project);
            }
          }
          this.sortISOs();
        });
    },
    expandIso(e, iso) {
      e.stopPropagation();
      iso.shouldShow = !iso.shouldShow;
      this.sortISOs();
    },
    openGitlabURL(e, project) {
      e.stopPropagation();
      ArcadiaBackend.Project.getProjectByName(project).then((p) => {
        const gitlabToken = session.getGitlabToken();
        const id = p.gitlabProjectId;
        AppStore.dispatch(ActionTypes.LOGIN, { gitlabToken, projectId: id })
          .then(() => {
            AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, p).then(() => {
              window.open(AppStore.state.data.gitlabProject.web_url);
            });
          });
      });
    },
    openProject(e, project) {
      e.stopPropagation();
      ArcadiaBackend.Project.getProjectByName(project).then((p) => {
        const gitlabToken = session.getGitlabToken();
        const id = p.gitlabProjectId;
        AppStore.dispatch(ActionTypes.LOGIN, { gitlabToken, projectId: id })
          .then(() => {
            AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, p);
            router.push(`/projects/${p.name}/devices`);
          });
      });
    },
    getDate(date) {
      return new Date(date).toLocaleDateString();
    },
    selectISO(e, iso) {
      var url = this.fixString('arcaderestore:infos=' + iso.IsoId + '*' + iso.projectName + '*' + iso.version + '*' + iso.motherboard +
        '*' + iso.hardDrive + '*' + iso.graphicCard + '*' + iso.processor + '*' + iso.gitlabURL + // jusqu'a 8 fonctionne
        '*' + iso.ISOURL + '*' + iso.buildNumber + '*' + iso.date + '*' + iso.archive);
      console.log(url);
      window.open(url);
      e.stopPropagation();
    },
    fixString(str) {
      return str.toString().replace(/ /g, '_').replace(/\\/g, '\\\\');
    },
  },
  created() {
    this.loadData(this);
    for (let i = 0; i < this.ISOs.length; i++) {
      this.set(this.ISOs[i], 'shouldShow', true);
    }
  },
  components: { ArcadiaUi },
};
</script>
