var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: false, topbar: false } },
    [
      _c(
        "v-content",
        [
          _c("v-progress-circular", {
            attrs: { size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }