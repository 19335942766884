import { router } from '@/_routing';
import AppStore from '@/stores/AppStore';
import MutationTypes from '@/stores/MutationTypes';
import ActionTypes from '@/stores/ActionTypes';

const exports = {};
export default exports;


// -- Auth ------

exports.isAuthenticated = () => {
  return !!AppStore.getters.isAuthenticated;
};

exports.getBackendToken = () => {
  return AppStore.state.auth.backendToken;
};

exports.getGitlabToken = () => {
  return AppStore.getters.backendTokenClaims.token;
};

exports.login = () => {
  if (exports.isAuthenticated()) {
    AppStore.commit(MutationTypes.LOGOUT);
  }
  router.push('/login');
};

exports.init = () => AppStore.dispatch(ActionTypes.SETUP_KEEPALIVE);


// -- Prefs ------

exports.getUserPref = (k) => {
  return AppStore.state.prefs[k];
};
