var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                staticStyle: { width: "100%" },
                attrs: {
                  headers: [
                    { text: _vm.$t("label.id"), value: "_id" },
                    { text: _vm.$t("label.name"), value: "name" },
                    { text: _vm.$t("label.group"), value: "groupName" },
                    { text: _vm.$t("label.lastUpdate"), value: "lastUpdate" },
                  ],
                  items: _vm.devices,
                  "hide-actions": true,
                  loading: _vm.loading(),
                  "rows-per-page-text": _vm.$t("label.rowsPerPage"),
                  "no-data-text": _vm.$t("label.noData"),
                  "no-results-text": _vm.$t("label.noResult"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "pageText",
                    fn: function (props) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("label.pagination", props)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c(
                          "tr",
                          {
                            staticClass: "clickable",
                            attrs: { slot: "activator" },
                            on: {
                              click: function ($event) {
                                return _vm.gotoDeviceDetails(
                                  props.item.dongleId
                                )
                              },
                            },
                            slot: "activator",
                          },
                          [
                            _c("td", [_vm._v(_vm._s(props.item.dongleId))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.groupName))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.lastUpdateText)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("v-tooltip", { attrs: { top: "" } }, [
                                  _c(
                                    "span",
                                    [_c("v-icon", [_vm._v("description")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("action.edit", props))
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }