var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  rules: [
                    (v) => !!v || _vm.$t("error.nameRequired"),
                    (v) => v.length >= 4 || _vm.$t("error.nameLength"),
                  ],
                  label: _vm.$t("label.name"),
                },
                model: {
                  value: _vm.group.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.group, "name", $$v)
                  },
                  expression: "group.name",
                },
              }),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.availableBuilds,
                  "item-text": "label",
                  "item-value": "value",
                  label: "Target Build",
                  loading: _vm.availableBuilds.length == 0,
                  outline: "",
                },
                model: {
                  value: _vm.group.targetBuild,
                  callback: function ($$v) {
                    _vm.$set(_vm.group, "targetBuild", $$v)
                  },
                  expression: "group.targetBuild",
                },
              }),
              _vm._v(" "),
              _c("v-autocomplete", {
                attrs: {
                  rules: [(v) => !!v || "Access right are required"],
                  items: _vm.availableRights,
                  "item-text": "label",
                  "item-value": "value",
                  label: _vm.$t("label.access"),
                  outline: "",
                },
                model: {
                  value: _vm.group.accessRight,
                  callback: function ($$v) {
                    _vm.$set(_vm.group, "accessRight", $$v)
                  },
                  expression: "group.accessRight",
                },
              }),
              _vm._v(" "),
              _c("v-switch", {
                attrs: {
                  "false-value": _vm.metricLogLevels.None,
                  "true-value": _vm.metricLogLevels.All,
                  color: "success",
                  label: _vm.$t("label.metric"),
                  outline: "",
                },
                model: {
                  value: _vm.group.metricLogLevel,
                  callback: function ($$v) {
                    _vm.$set(_vm.group, "metricLogLevel", $$v)
                  },
                  expression: "group.metricLogLevel",
                },
              }),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "290" },
                  model: {
                    value: _vm.saveDialog,
                    callback: function ($$v) {
                      _vm.saveDialog = $$v
                    },
                    expression: "saveDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.$t("title.confirm"))),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _vm._v(_vm._s(_vm.$t("message.confirmMetricOff"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("div", { staticClass: "flex-grow-1" }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmSave()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.yes")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.denySave()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.no")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "290" },
                  model: {
                    value: _vm.saveDowngradeDialog,
                    callback: function ($$v) {
                      _vm.saveDowngradeDialog = $$v
                    },
                    expression: "saveDowngradeDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.$t("title.confirm"))),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _vm._v(_vm._s(_vm.$t("message.confirmDowngrade"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("div", { staticClass: "flex-grow-1" }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmSave()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.yes")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.denySave()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.no")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "290" },
                  model: {
                    value: _vm.deleteDialog,
                    callback: function ($$v) {
                      _vm.deleteDialog = $$v
                    },
                    expression: "deleteDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.$t("title.confirm"))),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _vm._v(_vm._s(_vm.$t("message.confirmDelete"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("div", { staticClass: "flex-grow-1" }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteGroup()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.yes")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.denyDelete()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.no")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "green" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSave()
                    },
                  },
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "red" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDelete()
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }