var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: false } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                staticStyle: { width: "100%" },
                attrs: {
                  headers: [{ text: _vm.$t("label.name"), value: "name" }],
                  items: _vm.projects,
                  "hide-actions": true,
                  "no-data-text": _vm.$t("label.noData"),
                  "no-results-text": _vm.$t("label.noResult"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c(
                          "tr",
                          {
                            staticClass: "clickable",
                            on: {
                              click: function ($event) {
                                return _vm.selectProject(props.item)
                              },
                            },
                          },
                          [
                            _c("td", [
                              _c("img", {
                                attrs: {
                                  width: "30px",
                                  height: "30px",
                                  src: props.item.avatar || null,
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("td", { attrs: { "text-align": "left" } }, [
                              _vm._v(_vm._s(props.item.name)),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.gitlabProjectId)),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }