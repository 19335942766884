var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "justify-start": "" } },
                [
                  _c("v-flex", [
                    _c("img", {
                      staticClass: "project-avatar-big",
                      attrs: { src: _vm.currentGlProject.avatar_url },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.currentGlProject.web_url,
                          target: "_blank",
                        },
                      },
                      [
                        _c("h2", [_vm._v(_vm._s(_vm.currentProject.name))]),
                        _vm._v(" "),
                        _c("div", { staticClass: "subheader" }, [
                          _vm._v(
                            "( " +
                              _vm._s(_vm.currentGlProject.name_with_namespace) +
                              " )"
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.currentGlProject.forked_from_project
                ? _c(
                    "v-layout",
                    { staticClass: "fork-info", attrs: { row: "" } },
                    [
                      _c("v-flex", [
                        _c("img", {
                          staticClass: "project-avatar",
                          attrs: {
                            src: _vm.currentGlProject.forked_from_project
                              .avatar_url,
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.currentGlProject.forked_from_project
                                .web_url,
                              target: "_blank",
                            },
                          },
                          [
                            _c("h3", [
                              _vm._v(
                                _vm._s(_vm.$t("label.forkedFrom")) +
                                  ": " +
                                  _vm._s(
                                    _vm.currentGlProject.forked_from_project
                                      .name
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "subheader" }, [
                              _vm._v(
                                "( " +
                                  _vm._s(
                                    _vm.currentGlProject.forked_from_project
                                      .name_with_namespace
                                  ) +
                                  " )"
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h3", [_vm._v(_vm._s(_vm.$t("label.details")) + ":")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.currentProject.description))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("label.creationDate")) +
                          ": " +
                          _vm._s(_vm.createdAt)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.allDevices.length) +
                          " " +
                          _vm._s(_vm.$t("label.devices")) +
                          " "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.allGroups.length) +
                          " " +
                          _vm._s(_vm.$t("label.groups"))
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.currentProject.metricsUrl
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.currentProject.metricsUrl,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("label.metricsForProject")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }