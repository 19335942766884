import DataModule from '@/stores/modules/DataModule';
import AuthModule from '@/stores/modules/AuthModule';
import PrefsModule from '@/stores/modules/PrefsModule';
import vuexPersistedState from 'vuex-persistedstate';
import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

const AppStore = new Vuex.Store({
  plugins: [
    vuexPersistedState({ paths: [
      'auth.gitlabToken',
      'auth.backendToken',
      'auth.keepalive',
      'prefs.locale',
    ] }),
  ],
  modules: {
    data: DataModule,
    auth: AuthModule,
    prefs: PrefsModule,
  },
});

export default AppStore;
