var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: false, topbar: false } },
    [
      _c(
        "v-content",
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("title.app")))]),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.login } },
            [
              _c("v-icon", { attrs: { left: "", dark: "" } }, [_vm._v("lock")]),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("action.loginGitlab")) + "\n    "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-checkbox", {
            attrs: { label: _vm.$t("label.stayConnected") },
            model: {
              value: _vm.keepalive,
              callback: function ($$v) {
                _vm.keepalive = $$v
              },
              expression: "keepalive",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }