<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>

        <h2>Device {{device.dongleId}}</h2>

        <v-text-field v-model="device.name" :label="$t('label.name')" />

        <v-autocomplete
          v-model="device.groupId"
          :items="availableGroups"
          item-text="label"
          item-value="value"
          :label="$t('label.group')"
          dense
        />
        <v-btn class="white--text" color="green" @click="save()">{{$t('action.save')}}</v-btn>
        <v-btn class="white--text" color="red" @click="remove()">{{$t('action.remove')}}</v-btn>
        <v-dialog v-model="deleteDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">{{
              $t("title.confirm")
            }}</v-card-title>
            <v-card-text>{{ $t("message.confirmDeleteDevice") }}</v-card-text>
            <v-card-actions>
              <div class="flex-grow-1" />
              <v-btn text @click="confirmDelete()">{{ $t("label.yes") }}</v-btn>
              <v-btn text @click="denyDelete()">{{ $t("label.no") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import ArcadiaBackend from '@/externals/ArcadiaBackend';
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';
import _ from 'lodash';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
    deviceId: { type: String, required: true },
  },
  data: () => ({
    deleteDialog: false,
    device: {
      _id: null,
      dongleId: null,
      name: '',
      groupId: null,
    },
  }),
  computed: {
    availableGroups() {
      return (AppStore.state.data.projectGroups || [])
        .map(g => ({ label: g.name, value: g._id }));
    },
    loading: () => AppStore.state.data.isLoading,
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName);
      AppStore.dispatch(ActionTypes.LOAD_DEVICES)
        .then((devices) => {
          const activeDevice = (devices || [])
            .find(d => d.dongleId === this.deviceId);
          Object.assign(this.device, _.cloneDeep(activeDevice));
        });
      AppStore.dispatch(ActionTypes.LOAD_GROUPS);
    },
    save() {
      ArcadiaBackend.Device.updateDevice(this.projectName, this.device._id, this.device)
        .then(() => AppStore.dispatch(ActionTypes.UNLOAD_DEVICES))
        .then(() => router.go(-1));
    },
    remove() {
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.deleteDialog = false;
      ArcadiaBackend.Device.deleteDevice(this.projectName, this.device._id)
        .then(() => AppStore.dispatch(ActionTypes.UNLOAD_DEVICES))
        .then(() => router.go(-1));
    },
    denyDelete() {
      this.deleteDialog = false;
    },
  },
  watch: {
    projectName: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
    deviceId: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
  },
  components: { ArcadiaUi },
};
</script>
