var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.gitlabProjects,
                      loading: _vm.gitlabProjects.length == 0,
                      "item-text": "name",
                      "item-value": "id",
                      label: _vm.$t("label.gitlabProject"),
                      rules: [
                        () =>
                          _vm.project.gitlabProjectId > 0 ||
                          "You must select a Gitlab project",
                      ],
                      dense: "",
                    },
                    model: {
                      value: _vm.project.gitlabProjectId,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "gitlabProjectId", $$v)
                      },
                      expression: "project.gitlabProjectId",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: { label: _vm.$t("label.description") },
                    model: {
                      value: _vm.project.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "description", $$v)
                      },
                      expression: "project.description",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: { label: _vm.$t("label.metricsUrl") },
                    model: {
                      value: _vm.project.metricsUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "metricsUrl", $$v)
                      },
                      expression: "project.metricsUrl",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("action.save")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }