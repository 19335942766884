<template>
  <v-toolbar app class="screen-topbar" clipped-left color="primary" dark>
    <h1>{{$t('title.app')}}</h1>

    <div class="root-nav">
      <v-btn v-for="navItem in rootNavItems" :key="navItem.route.name"
        :href="navItem.route.path | routeToHref"
        @click.prevent="navigateWithoutReload" flat>
        {{navItem.label}}
      </v-btn>
    </div>

    <v-spacer />

    <v-menu class="trace-menu" offset-y>
      <v-btn slot="activator" icon><v-icon>receipt</v-icon></v-btn>
      <v-list>
        <v-list-tile @click="seeLogs">
          <v-list-tile-title>See logs</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>

    <v-menu class="locale-menu" offset-y>
      <v-btn slot="activator" icon><v-icon>language</v-icon></v-btn>
      <v-list>
        <v-list-tile v-for="localeItem in localeItems" :key="localeItem.code"
          @click="changeLocale(localeItem.code)">
          <v-list-tile-title>{{localeItem.label}}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>

    <v-menu class="account-menu" offset-y>
      <v-btn slot="activator" icon><v-icon>account_circle</v-icon></v-btn>
      <v-list>
        <v-list-tile @click="logout">
          <v-list-tile-action><v-icon>exit_to_app</v-icon></v-list-tile-action>
          <v-list-tile-title>{{$t('action.logout')}}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import AppStore from '@/stores/AppStore';
import MutationTypes from '@/stores/MutationTypes';
import i18n from '@/i18n';
import { router, routes } from '@/_routing';
import appConfig from '@/appConfig';
import { filepathToComponentName, clickEventToHref } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  data: () => ({
    rootNavItems: [
      {
        label: i18n.t(`title.projects`),
        route: routes.byPath['/projects'],
      },
      {
        label: i18n.t(`title.newProject`),
        route: routes.byPath['/projects/new'],
      },
      {
        label: i18n.t(`title.gitlabProjects`),
        route: routes.byPath['/gitlab-projects'],
      },
      {
        label: i18n.t(`title.ISOs`),
        route: routes.byPath['/ISOs'],
      },
      {
        label: i18n.t(`title.archive`),
        route: routes.byPath['/archives'],
      },
    ],
    localeItems: [
      { label: 'Français', code: 'fr' },
      { label: 'English', code: 'en' },
      { label: 'Debug', code: 'debug', show: appConfig.featureSwitches.debugLocale },
    ].filter(v => v.show !== false),
  }),
  methods: {
    navigateWithoutReload(event) {
      router.push(clickEventToHref(event));
    },
    changeLocale(locale) {
      if (i18n.locale === locale) return;
      i18n.locale = locale;
      AppStore.commit(MutationTypes.SET_LOCALE, locale);
      window.location.reload();
    },
    logout() {
      AppStore.commit(MutationTypes.LOGOUT);
      router.push('/login');
    },
    seeLogs() {
      router.push('/logs');
    },
  },
};
</script>

<style>
.screen-topbar h1 {
  display: inline-block;
  width: 240px;
  margin: 0;
  margin-left: -16px !important;
  padding-left: 36px;
}

@media (min-width: 960px) {
  .screen-topbar h1 {
    margin-left: -24px !important;
  }
}
</style>
