import ArcadiaBackend from '@/externals/ArcadiaBackend';
import MutationTypes from '../MutationTypes';
import ActionTypes from '../ActionTypes';
const base64Decode = window.atob;

let keepaliveTimeout = null;

// there's something wrong here... project-specific login and keep-alive
// don't really make sense together
export default {
  state: {
    gitlabToken: null,
    backendToken: null,
    accessRight: 0,
    keepalive: true,
  },

  getters: {
    isAuthenticated(state, getters) {
      return !!state.gitlabToken && !!state.backendToken &&
        getters.backendTokenClaims.exp*1000 > Date.now()+500;
    },
    backendTokenClaims(state) {
      const claims = {};
      if (state.backendToken) {
        const encodedClaims = state.backendToken.split('.')[1];
        Object.assign(claims, JSON.parse(base64Decode(encodedClaims)));
      }
      return claims;
    },
    accessLevel(state, getters) {
      return getters.backendTokenClaims.accessLevel ? getters.backendTokenClaims.accessLevel : 0;
    },
  },

  actions: {
    [ActionTypes.LOGIN](context, { gitlabToken, projectId }) {
      if (context.state.gitlabToken !== gitlabToken) {
        context.commit(MutationTypes.SET_GITLAB_TOKEN, gitlabToken);
      }
      return ArcadiaBackend.login({ gitlabToken, projectId })
        .then(({ token }) => {
          context.commit(MutationTypes.SET_BACKEND_TOKEN, token);
          context.commit(MutationTypes.SET_ACCESS_RIGHT, context.getters.accessLevel);
          context.dispatch(ActionTypes.SETUP_KEEPALIVE, { reset: true });
        });
    },
    [ActionTypes.RENEW_TOKENS](context) {
      if (!context.getters.isAuthenticated) return;
      ArcadiaBackend.renewToken().then(({ token }) => {
        context.commit(MutationTypes.SET_BACKEND_TOKEN, token);
        context.dispatch(ActionTypes.SETUP_KEEPALIVE, { reset: true });
      });
    },
    [ActionTypes.SETUP_KEEPALIVE](context, opt = {}) {
      const { state, getters } = context;

      const shouldHaveTimeout = getters.isAuthenticated && state.keepalive;
      if (!!keepaliveTimeout === !!shouldHaveTimeout && !opt.reset) return;

      if (keepaliveTimeout) {
        clearTimeout(keepaliveTimeout);
        keepaliveTimeout = null;
      }

      if (shouldHaveTimeout) {
        const expirationTs = (getters.backendTokenClaims.exp || 0) * 1000;
        keepaliveTimeout = setTimeout(() => {
          context.dispatch(ActionTypes.RENEW_TOKENS);
        }, Math.max(0, expirationTs - Date.now() - 30*1000));
      }
    },
  },

  mutations: {
    [MutationTypes.SET_GITLAB_TOKEN](state, newToken) {
      state.gitlabToken = newToken;
    },
    [MutationTypes.SET_BACKEND_TOKEN](state, newToken) {
      state.backendToken = newToken;
    },
    [MutationTypes.SET_ACCESS_RIGHT](state, accessLevel) {
      state.accessRight = accessLevel;
    },
    [MutationTypes.LOGOUT](state) {
      state.gitlabToken = null;
      state.backendToken = null;
    },
    [MutationTypes.SET_KEEPALIVE](state, newValue) {
      state.keepalive = newValue;
    },
  },
};
