<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>
        <v-btn @click="generateXlsxFile()">{{$t('action.exportExcel')}}</v-btn>

        <a v-if="currentProject.metricsUrl"
          :href="currentProject.metricsUrl"
          target="_blank">
          {{$t('label.metricsForProject')}}
        </a>

        <v-layout row wrap>
          <v-flex xs3>
            <v-combobox
              v-model="filters.eventIds"
              :label="$t('label.events')"
              multiple
              chips
            />
          </v-flex>
          <v-flex xs3>
            <v-combobox
              v-model="filters.dongleIds"
              :label="$t('label.devices')"
              multiple
              chips
            />
          </v-flex>
          <v-flex xs2>
            <date-picker
              :label="$t('label.dateFrom')"
              v-model="filters.dateFrom"
            />
          </v-flex>
          <v-flex xs2>
            <date-picker
              :label="$t('label.dateTo')"
              v-model="filters.dateTo"
            />
          </v-flex>
          <v-flex xs2>
            <v-btn @click="refreshList()">{{$t('action.filter')}}</v-btn>
          </v-flex>
        </v-layout>

        <v-data-table style="width: 100%"
          :headers="[
            { text: $t('label.event'), value: 'event' },
            { text: $t('label.timestamp'), value: 'ts' },
            { text: $t('label.deviceId'), value: 'deviceId' },
            { text: $t('label.sessionId'), value: 'sessionId' },
            { text: $t('label.data'), value: 'data' },
          ]"
          :items="metrics"
          :pagination.sync="pagination"
          :rows-per-page-text="$t('label.rowsPerPage')"
          :no-data-text="$t('label.noData')"
          :no-results-text="$t('label.noResult')"
          class="elevation-1">
          <template slot="pageText" slot-scope="props">
            {{$t('label.pagination', props)}}
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{props.item.event}}</td>
              <td>{{props.item.ts}}</td>
              <td>{{props.item.deviceId}}</td>
              <td>{{props.item.sessionId}}</td>
              <td>{{props.item.data}}</td>
            </tr>
          </template>
        </v-data-table>

      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaBackend from '@/externals/ArcadiaBackend';
import DatePicker from '../widgets/DatePicker';
import Xlsx from 'xlsx';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
  },
  data: () => ({
    metrics: [],
    filters: {
      dateFrom: defaultFromDate(),
      dateTo: new Date().toISOString().substr(0, 10),
      dongleIds: [],
      eventIds: [],
      // sessionIds: [],
      // builds: [],
    },
    pagination: {
      sortBy: 'ts',
      descending: true,
      rowsPerPage: 10,
      page: 1,
    },
  }),
  computed: {
    currentProject() {
      return AppStore.state.data.project || {};
    },
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName);
      this.loadMetrics();
    },
    loadMetrics() {
      // load metrics
      const query = {
        dateFrom: new Date(this.filters.dateFrom).getTime() / 1000,
        dateTo: new Date(this.filters.dateTo).getTime() / 1000,
        dongleIds: this.filters.dongleIds,
        eventIds: this.filters.eventIds,
        // sessionIds: this.filters.sessionIds,
        // builds: this.filters.builds,
      };
      const paging = { currentPage: 1, resultPerPage: 1000 };
      ArcadiaBackend.Metric.getMetrics(this.projectName, query, paging)
        .then(res => { this.metrics = res; });
    },
    generateXlsxFile() {
      const metrics = this.metrics.map((event) => {
        event.data = JSON.stringify(event.data);
        return event;
      });
      const worksheet = Xlsx.utils.json_to_sheet(metrics);
      const workbook = Xlsx.utils.book_new();
      Xlsx.utils.book_append_sheet(
        workbook,
        worksheet,
        `Metrics for ${this.projectName}`
      );
      Xlsx.writeFile(
        workbook,
        `${this.projectName}_metrics_${this.filters.dateFrom}_to_${this.filters.dateTo}.xlsx`
      );
    },
  },
  watch: {
    projectName: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
  },
  components: { ArcadiaUi, DatePicker },
};

const defaultFromDate = () => {
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 3);
  return fromDate.toISOString().substr(0, 10);
};

</script>
