const exports = {};
export default exports;

exports.findErrorHandler = (res) => {
  const handler = handlers.find(v => v.filter(res));
  return handler && handler.handler;
};

const handlers = [];

// invalid login
handlers.push({
  filter: res => res.status === 400 &&
    res.data.error === 'Invalid login information',
  handler: () => {
    alert('Bad username and password combination');
  },
});

// generic 400
handlers.push({
  filter: res => res.status === 400,
  handler: () => {
    alert('Some of the data you provided is incorrect or the server validations are too strict');
  },
});

// session expired
handlers.push({
  filter: res => res.status === 401,
  handler: (res) => {
    if (res.data.message === 'jwt expired') {
      alert('Session expired, you need to log in again');
    }
  },
});

// not enough right
handlers.push({
  filter: res => res.status === 403,
  handler: () => {
    alert('Access right are required');
  },
});

// duplicate
handlers.push({
  filter: res => res.status === 409,
  handler: () => {
    alert('This element already exist');
  },
});
