import App from './App';
import { router } from './_routing';
import i18n from './i18n';
import session from './session';
import Vuetify from 'vuetify';
import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';

const entrypoint = () => {
  Vue.config.productionTip = false;
  // Vue.use(VueRouter) in @/_routing.js
  // Vue.use(Vuex) in @/stores/AppStore.js
  // Vue.use(VueI18n) in @/i18n.js
  Vue.use(Vuetify);

  initVueRoot('#vue-root', {
    template: '<App/>',
    router,
    i18n,
    mounted() { session.init(); },
    components: { App },
  });
};

const initVueRoot = (el, params) => new Vue({ el, ...params });

entrypoint();
