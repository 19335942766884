<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>
        <v-data-table
          :headers="[
            { text: $t('label.version'), value: 'version' },
            { text: $t('label.date'), value: 'modified' },
            { text: $t('label.filename'), value: 'tags.filename' },
            { text: $t('label.branch'), value: 'tags.ref' },
            { text: $t('label.job'), value: 'tags.job' },
            { text: $t('label.size'), value: 'size' },
          ]"
          :items="builds"
          :pagination.sync="pagination"
          :loading="loading"
          :rows-per-page-text="$t('label.rowsPerPage')"
          :no-data-text="$t('label.noData')"
          :no-results-text="$t('label.noResult')"
          class="elevation-1" style="width: 100%">
          <template slot="pageText" slot-scope="props">
            {{$t('label.pagination', props)}}
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{props.item.version}}</td>
              <td>{{formatDate(props.item.modified)}}</td>
              <td>{{props.item.tags["X-Amz-Meta-Filename"]}}</td>
              <td>{{props.item.tags["X-Amz-Meta-Ref"]}}</td>
              <td>{{props.item.tags["X-Amz-Meta-Job"]}}</td>
              <td>{{Math.round(props.item.size / (1000*1000) * 100) / 100}} MB</td>
              <td>
                <v-tooltip top>
                  <span slot="activator"
                    @click="downloadBuild(props.item.version)" class="clickable">
                    <v-icon medium>cloud_download</v-icon>
                  </span>
                  <span>{{$t('action.downloadBuild', props)}}</span>
                </v-tooltip>
                <v-tooltip v-if="props.item.buildType !== 'live'" top>
                  <span slot="activator"
                    @click="makeBuildPermanent(props.item.version)" class="clickable">
                    <v-icon medium>cloud_upload</v-icon>
                  </span>
                  <span>{{$t('action.uploadLive', props)}}</span>
                </v-tooltip>
                <v-tooltip v-if="props.item.buildType === 'live'" top>
                  <span slot="activator" class="transparent">
                    <v-icon medium>cloud_done</v-icon>
                  </span>
                  <span>{{$t('action.uploaded', props)}}</span>
                </v-tooltip>
                <v-tooltip v-if="buildIsUsed(props.item.version)" top>
                  <span slot="activator">
                    <v-icon medium>all_inclusive</v-icon>
                  </span>
                  <span>{{$t('action.used', props)}}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaBackend from '@/externals/ArcadiaBackend';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
  },
  data: () => ({
    pagination: {
      sortBy: 'modified',
      descending: true,
      rowsPerPage: 25,
      page: 1,
    },
  }),
  computed: {
    builds: () => AppStore.state.data.projectBuilds || [],
    loading: () => AppStore.state.data.isLoading,
    groups: () => AppStore.state.data.projectGroups || [],
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName);
      AppStore.dispatch(ActionTypes.LOAD_BUILDS, { refresh: true });
      AppStore.dispatch(ActionTypes.LOAD_GROUPS, { refresh: true });
    },
    downloadBuild(version) {
      ArcadiaBackend.Build.getBuildDownloadUrl(this.projectName, version)
        .then(url => window.open(url, '_blank'));
    },
    makeBuildPermanent(version) {
      AppStore.dispatch(ActionTypes.MAKE_BUILD_PERMANENT, {
        projectName: this.projectName, version });
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString();
    },
    buildIsUsed(version) {
      const parent = this.groups.find(g => g.targetBuild === version);
      if (parent) { return true; }

      return false;
    },
  },
  watch: {
    projectName: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
  },
  components: { ArcadiaUi },
};
</script>

<style>
.build-list-screen .v-icon {
  margin: 0 5px;
}
.build-list-screen .transparent {
  opacity: 0.5;
}
</style>
