<template>
  <arcadia-ui :sidebar="false">
    <v-content>
      <pre>{{JSON.stringify(appInfo, null, 2)}}</pre>
    </v-content>
  </arcadia-ui>
</template>

<script>
import appConfig from '@/appConfig.js';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  data: () => {
    const appInfo = Object.assign({}, window.APP_INFO);
    appInfo.config = appConfig;
    return { appInfo };
  },
  components: { ArcadiaUi },
};
</script>

<style>
.app-info-screen pre {
  margin: 2em;
}
</style>
