<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>
        <v-form v-model="valid">
          <v-autocomplete
            v-model="project.gitlabProjectId"
            :items="gitlabProjects"
            :loading="gitlabProjects.length == 0"
            item-text="name"
            item-value="id"
            :label="$t('label.gitlabProject')"
            :rules="[
              () => project.gitlabProjectId > 0 || 'You must select a Gitlab project',
            ]"
            dense
          />

          <v-text-field
            v-model="project.description"
            :label="$t('label.description')"
          />

          <v-text-field
            v-model="project.metricsUrl"
            :label="$t('label.metricsUrl')"
          />

          <v-btn @click="save" :disabled="!valid">
            {{$t('action.save')}}
          </v-btn>
        </v-form>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaBackend from '@/externals/ArcadiaBackend';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';
import _ from 'lodash';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
  },
  data: () => ({
    project: {
      gitlabProjectId: 0,
      description: '',
      metricsUrl: '',
    },
    valid: true,
  }),
  computed: {
    gitlabProjects() {
      const projects = AppStore.state.data.gitlabProjects[1] || [];
      return projects.map(p => ({ name: p.name, id: p.id }));
    },
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName)
        .then(project => Object.assign(this.project, _.cloneDeep(project)));
    },
    save() {
      ArcadiaBackend.Project.updateProject(this.projectName, this.project)
        .then(() => { router.push(`/projects/${this.projectName}`); });
    },
  },
  watch: {
    projectName: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
  },
  created() {
    AppStore.dispatch(ActionTypes.LOAD_GL_PROJECTS, {
      perPage: 100,
      maxPages: 1,
      page: 1,
      showPagination: true,
    });
  },
  components: { ArcadiaUi },
};
</script>
