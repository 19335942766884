export default {
  action: {
    archive: `Archive`,
    collapseSidebar: `Collapse sidebar`,
    commit: `Commit`,
    create: `Create`,
    downloadBuild: `Download build`,
    edit: `Edit`,
    exportExcel: `Export for Excel`,
    filter: `Filter`,
    login: `Login`,
    loginGitlab: `Login with Gitlab`,
    logout: `Logout`,
    remove: `Delete`,
    reset: `Reset`,
    restore: `Restore`,
    return: `Return`,
    save: `Save`,
    saveSchema: `Save schema`,
    used: `Currently in use`,
    uploaded: `Uploaded`,
    uploadLive: `Upload build Live`,
  },
  label: {
    access: `Access Right`,
    all: `All`,
    artifacts: `Artifacts`,
    branch: `Branch`,
    build: `Build`,
    children: `Children`,
    commit: `Commit`,
    config: `Config`,
    creationDate: `Creation date`,
    data: `Data`,
    date: `Date`,
    dateFrom: `Date From`,
    dateTo: `Date To`,
    description: `Description`,
    details: `Details`,
    deviceId: `Device Id`,
    devices: `Devices`,
    devicesInGroup: `Devices in group`,
    endpoint: `Endpoint`,
    event: `Event`,
    events: `Events`,
    filename: `Filename`,
    forkedFrom: `Forked from`,
    gitlabProject: `Gitlab project`,
    graphicCard: `Graphics card`,
    gridView: `Grid View`,
    group: `Group`,
    groups: `Groups`,
    hardDrive: `Hard drive`,
    id: `Id`,
    job: `Job`,
    key: `Key`,
    lastUpdate: `Last update check`,
    material: `Material`,
    metaData: `Meta data`,
    metric: `Send metrics`,
    metricsForProject: `Metrics for this project`,
    metricsUrl: `Metrics Url`,
    motherboard: `Motherboard`,
    name: `Name`,
    nbDevice: `Number of devices`,
    no: `No`,
    noData: `No available data`,
    noResult: `No result found`,
    pagination: `{pageStart} - {pageStop} of {itemsLength}`,
    params: `Parameter`,
    parent: `Parent`,
    password: `Password`,
    processor: `Processor`,
    project: `Project`,
    rowsPerPage: `Rows per page`,
    sessionId: `Session Id`,
    size: `Size`,
    status: `Status`,
    stayConnected: `Stay Connected`,
    targetBuild: `Target Build`,
    timestamp: `Timestamp`,
    treeView: `Tree View`,
    user: `User`,
    username: `Username`,
    version: `Version`,
    yes: `Yes`,
  },
  message: {
    confirmMetricOff: `You will make it so every arcade in this group will stop sending metrics. If you proceed, an e-mail will
    be sent to the lead designer.`,
    confirmArchive: `The project will be moved to the archive section, this is reversible.`,
    confirmDelete: `!!!!WARNING!!!! You are about to delete the group, this is NOT reversible, proceed with caution!`,
    confirmDeleteDevice: `!!!!WARNING!!!! You are about to delete the device, this is NOT reversible, proceed with caution!`,
    confirmDowngrade: `You will downgrade the version of this project for this group. Do you continue?`,
    gitlabRedirection: `You will be redirected to Gitlab for authentification`,
    nothingToChange: `Nothing to change`,
    somethingNotRight: `Something's not right`,
    validateChanges: `Please validate your changes`,
    showArchive: `Show archives`,
  },
  title: {
    app: `Arcadia`,
    archive: `Archive`,
    confirm: `Are you sure you want to do that?`,
    // root nav
    projects: `Projects`,
    newProject: `New Project`,
    ISOs: `ISOs`,
    gitlabProjects: `Gitlab Projects`,
    // project nav
    projectDetails: `Project Details`,
    projectBuilds: `Builds`,
    projectDevices: `Devices`,
    projectGroups: `Groups`,
    projectMetrics: `Metrics`,
    projectSettings: `Settings`,
  },
  error: {
    badAuhth: `Bad username and password combination`,
    descriptionRequired: `Description is required`,
    descriptionLength: `Description must be at least 4 characters long`,
    duplicated: `This element already exist`,
    genericRequestError: `Some of the data you provided is incorrect or the server validations are too strict`,
    nameRequired: `Name is required`,
    nameLength: `Name must be at least 4 characters long`,
    noData: `No data available`,
    rightRequired: `Access right are required`,
    sessionExpired: `Session expired, you need to log in again`,
  },
};
