import _ from 'lodash';

export function jsonFormat(data) {
  return JSON.stringify(data, null, 2);
}

export function getTimestampFromObjectId(objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
}

export function filepathToComponentName(path) {
  const parts = path.split(/[\\/]/);
  const filename = parts[parts.length - 1].split('?')[0].replace(/\.vue$/, '');
  return _.kebabCase(filename);
};

export function clickEventToHref(event) {
  for (let el = event.target; el != null; el = el.parentNode) {
    if (el.tagName === 'A') return el.getAttribute('href');
  }
  return null;
}
