var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    {
      staticClass: "screen-topbar",
      attrs: { app: "", "clipped-left": "", color: "primary", dark: "" },
    },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("title.app")))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "root-nav" },
        _vm._l(_vm.rootNavItems, function (navItem) {
          return _c(
            "v-btn",
            {
              key: navItem.route.name,
              attrs: {
                href: _vm._f("routeToHref")(navItem.route.path),
                flat: "",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.navigateWithoutReload.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(navItem.label) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-menu",
        { staticClass: "trace-menu", attrs: { "offset-y": "" } },
        [
          _c(
            "v-btn",
            { attrs: { slot: "activator", icon: "" }, slot: "activator" },
            [_c("v-icon", [_vm._v("receipt")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            [
              _c(
                "v-list-tile",
                { on: { click: _vm.seeLogs } },
                [_c("v-list-tile-title", [_vm._v("See logs")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-menu",
        { staticClass: "locale-menu", attrs: { "offset-y": "" } },
        [
          _c(
            "v-btn",
            { attrs: { slot: "activator", icon: "" }, slot: "activator" },
            [_c("v-icon", [_vm._v("language")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            _vm._l(_vm.localeItems, function (localeItem) {
              return _c(
                "v-list-tile",
                {
                  key: localeItem.code,
                  on: {
                    click: function ($event) {
                      return _vm.changeLocale(localeItem.code)
                    },
                  },
                },
                [_c("v-list-tile-title", [_vm._v(_vm._s(localeItem.label))])],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-menu",
        { staticClass: "account-menu", attrs: { "offset-y": "" } },
        [
          _c(
            "v-btn",
            { attrs: { slot: "activator", icon: "" }, slot: "activator" },
            [_c("v-icon", [_vm._v("account_circle")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            [
              _c(
                "v-list-tile",
                { on: { click: _vm.logout } },
                [
                  _c(
                    "v-list-tile-action",
                    [_c("v-icon", [_vm._v("exit_to_app")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-tile-title", [
                    _vm._v(_vm._s(_vm.$t("action.logout"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }