var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: false } },
    [
      _c("v-content", [
        _c("h2", [_vm._v("Not Found")]),
        _vm._v(" "),
        _c("h3", [_vm._v("The requested url doesn't exist")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }