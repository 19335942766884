var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: false } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                staticStyle: { width: "100%" },
                attrs: {
                  headers: [
                    { sortable: false },
                    { text: _vm.$t("label.id"), value: "id" },
                    { text: _vm.$t("label.name"), value: "name" },
                    { text: _vm.$t("label.creationDate"), value: "created_at" },
                    { text: _vm.$t("label.lastUpdate"), value: "updated_at" },
                    { sortable: false },
                  ],
                  items: _vm.currentPageItems,
                  pagination: _vm.pagination,
                  "total-items": _vm.totalItems,
                  loading: _vm.loading,
                  "rows-per-page-text": _vm.$t("label.rowsPerPage"),
                  "no-data-text": _vm.$t("label.noData"),
                  "no-results-text": _vm.$t("label.noResult"),
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "pageText",
                    fn: function (props) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("label.pagination", props)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("tr", [
                          _c("td", [
                            _c("img", {
                              staticClass: "project-avatar",
                              attrs: { src: props.item.avatar_url },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.id))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.name))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.created_at))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.updated_at))]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "clickable",
                                attrs: {
                                  target: "_blank",
                                  href: props.item.web_url,
                                },
                              },
                              [_c("v-icon", [_vm._v("open_in_new")])],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }