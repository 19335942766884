<template>
  <arcadia-ui :sidebar="false">
    <v-content>
      <v-container fluid>
        <v-card-title>
          Logs
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table dense style="width: 100%"
          :headers="[
            { text: $t('label.date'), value: 'ts' },
            { text: $t('label.name'), value: 'name' },
            { text: $t('label.endpoint'), value: 'endpoint' },
            { text: $t('label.params'), value: 'params' },
          ]"
          :search="search"
          :items="logs"
          :hide-actions="true"
          :loading="loading()"
          class="elevation-1">
          <template slot="items" slot-scope="props">
            <tr slot="activator">
              <td>{{timeConverter(props.item.ts)}}</td>
              <td>{{props.item.name}}</td>
              <td>{{props.item.endpoint}}</td>
              <td>{{props.item.params}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { format } from 'date-fns';
export default {
  computed: {
    logs: () => AppStore.state.data.logs || [],
  },
  methods: {
    loading: () => AppStore.state.data.isLoading,
    timeConverter: (ts) => format(ts, 'dd MMM yyyy HH:mm:ss'),
  },
  created: function() {
    AppStore.dispatch(ActionTypes.LOAD_LOGS);
  },
  components: { ArcadiaUi },
  data() {
    return {
      search: '',
    };
  },
};
</script>
