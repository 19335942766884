export default {
  // active projects & related data
  SET_PROJECT_NAME: 'SET_PROJECT_NAME',
  SET_PROJECT: 'SET_PROJECT',
  SET_PROJECT_BUILDS: 'SET_PROJECT_BUILDS',
  SET_PROJECT_DEVICES: 'SET_PROJECT_DEVICES',
  SET_PROJECT_GROUPS: 'SET_PROJECT_GROUPS',
  SET_LOGS: 'SET_LOGS',

  // edits
  UPDATE_BUILD: 'UPDATE_BUILD',
  UPDATE_GROUP: 'UPDATE_GROUP',

  // auth & prefs
  SET_GITLAB_TOKEN: 'SET_GITLAB_TOKEN',
  SET_BACKEND_TOKEN: 'SET_BACKEND_TOKEN',
  SET_ACCESS_RIGHT: 'SET_ACCESS_RIGHT',
  LOGOUT: 'LOGOUT',
  SET_KEEPALIVE: 'SET_KEEPALIVE',
  SET_LOCALE: 'SET_LOCALE',
};
