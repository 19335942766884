<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>
        <v-text-field
          v-model="group.name"
          :rules="[
            v => !!v || $t('error.nameRequired'),
            v => v.length >= 4 || $t('error.nameLength')
          ]"
          :label="$t('label.name')"
        />
        <v-spacer />
        <v-autocomplete
          :items="availableBuilds"
          item-text="label"
          item-value="value"
          label="Target Build"
          v-model="group.targetBuild"
          :loading="availableBuilds.length == 0"
          outline
        />

        <v-autocomplete
          v-model="group.accessRight"
          :rules="[v => !!v || 'Access right are required']"
          :items="availableRights"
          item-text="label"
          item-value="value"
          :label="$t('label.access')"
          outline
        />

        <v-switch
          :false-value="metricLogLevels.None"
          :true-value="metricLogLevels.All"
          color="success"
          :label="$t('label.metric')"
          v-model="group.metricLogLevel"
          outline
        />
        <v-dialog v-model="saveDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">{{
              $t("title.confirm")
            }}</v-card-title>
            <v-card-text>{{ $t("message.confirmMetricOff") }}</v-card-text>
            <v-card-actions>
              <div class="flex-grow-1" />
              <v-btn text @click="confirmSave()">{{ $t("label.yes") }}</v-btn>
              <v-btn text @click="denySave()">{{ $t("label.no") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="saveDowngradeDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">{{
              $t("title.confirm")
            }}</v-card-title>
            <v-card-text>{{ $t("message.confirmDowngrade") }}</v-card-text>
            <v-card-actions>
              <div class="flex-grow-1" />
              <v-btn text @click="confirmSave()">{{ $t("label.yes") }}</v-btn>
              <v-btn text @click="denySave()">{{ $t("label.no") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">{{
              $t("title.confirm")
            }}</v-card-title>
            <v-card-text>{{ $t("message.confirmDelete") }}</v-card-text>
            <v-card-actions>
              <div class="flex-grow-1" />
              <v-btn text @click="deleteGroup()">{{ $t("label.yes") }}</v-btn>
              <v-btn text @click="denyDelete()">{{ $t("label.no") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn class="white--text" color="green" @click="confirmSave()">Save</v-btn>
        <v-btn class="white--text" color="red" @click="confirmDelete()">Delete</v-btn>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import SarbaGitlab from '@/externals/SarbaGitlab';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';
import _ from 'lodash';

const metricLogLevels = {
  None: 0,
  Hardware: 1,
  Adrenaline: 2,
  DesignQuiet: 3,
  All: 4,
};

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
    groupName: { type: String, required: true },
  },
  data: () => ({
    saveDialog: false,
    saveDowngradeDialog: false,
    deleteDialog: false,
    metricLogLevels,
    initialLogLevel: metricLogLevels.All,
    initialBuild: 0,
    initialName: '',
    initialaccessRight: 0,
    group: {
      _id: null,
      name: '',
      parent: null,
      targetBuild: null,
      accessRight: 0,
      metricLogLevel: metricLogLevels.All,
    },
  }),
  computed: {
    availableBuilds() {
      return (AppStore.state.data.projectBuilds || [])
        .filter(build => build.buildType === 'live')
        .map(build => {
          const numericVersion = build.version.match(/\d+/g).map(Number);
          return {
            label: `${numericVersion} - [${build.tags['X-Amz-Meta-Ref']}]: ${build.tags['X-Amz-Meta-Filename']}`,
            value: build.version,
            numericVersion,
          };
        })
        .sort((a, b) => b.numericVersion - a.numericVersion);
    },
    availableRights() {
      return SarbaGitlab.accessRights;
    },
    loading: () => AppStore.state.data.isLoading,
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName);
      AppStore.dispatch(ActionTypes.LOAD_GROUPS).then(groups => {
        const activeGroup = (groups || []).find(g => g.name === this.groupName);
        Object.assign(this.group, _.cloneDeep(activeGroup));
        this.group.metricLogLevel = activeGroup.config.metricLogLevel; // Quickfix
        this.initialLogLevel = activeGroup.config.metricLogLevel;
        this.initialBuild = this.group.targetBuild;
        this.initialName = activeGroup.name;
        this.initialaccessRight = activeGroup.accessRight;
      });
      AppStore.dispatch(ActionTypes.LOAD_BUILDS);
    },
    denySave() {
      this.group.metricLogLevel = metricLogLevels.All;
      this.group.targetBuild = this.initialBuild;
      this.saveDowngradeDialog = false;
      this.saveDialog = false;
    },
    confirmSave() {
      if (!this.saveDialog && this.group.metricLogLevel !== this.initialLogLevel && this.group.metricLogLevel === metricLogLevels.None) {
        this.saveDialog = true;
        return;
      }
      if (!this.saveDowngradeDialog && this.initialBuild > this.group.targetBuild) {
        this.saveDowngradeDialog = true;
        return;
      }
      this.save();
    },
    save() {
      this.saveDowngradeDialog = false;
      this.saveDialog = false;
      this.initialBuild = this.group.targetBuild;
      Promise.resolve()
        .then(() =>
          AppStore.dispatch(ActionTypes.SAVE_GROUP_DEPLOY_CONFIG, {
            projectName: this.projectName,
            groupId: this.group._id,
            deployConfig: _.pick(this.group, 'targetBuild'),
          })
        )
        .then(() => {
          if (this.initialaccessRight !== this.group.accessRight ||
            this.initialName !== this.group.name ||
            this.initialLogLevel !== this.group.metricLogLevel) {
            AppStore.dispatch(ActionTypes.SAVE_GROUP_CONFIG, {
              projectName: this.projectName,
              groupId: this.group._id,
              config: _.pick(this.group, 'accessRight', 'metricLogLevel', 'name'),
            });
          }
        })
        .then(() => AppStore.dispatch(ActionTypes.UNLOAD_GROUPS))
        .then(() => {
          router.go(-1);
        });
    },
    confirmDelete() {
      this.deleteDialog = true;
    },
    deleteGroup() {
      this.deleteDialog = false;
      Promise.resolve()
        .then(() =>
          AppStore.dispatch(ActionTypes.DELETE_GROUP, {
            projectName: this.projectName,
            groupId: this.group._id,
          })
        )
        .then(() => AppStore.dispatch(ActionTypes.UNLOAD_GROUPS))
        .then(() => {
          router.go(-1);
        });
    },
    denyDelete() {
      this.deleteDialog = false;
    },
  },
  watch: {
    projectName: {
      handler() {
        this.loadProjectData();
      },
      immediate: true,
    },
    groupName: {
      handler() {
        this.loadProjectData();
      },
      immediate: true,
    },
  },
  components: { ArcadiaUi },
};
</script>
