var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.generateXlsxFile()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("action.exportExcel")))]
              ),
              _vm._v(" "),
              _vm.currentProject.metricsUrl
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.currentProject.metricsUrl,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("label.metricsForProject")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          label: _vm.$t("label.events"),
                          multiple: "",
                          chips: "",
                        },
                        model: {
                          value: _vm.filters.eventIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "eventIds", $$v)
                          },
                          expression: "filters.eventIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          label: _vm.$t("label.devices"),
                          multiple: "",
                          chips: "",
                        },
                        model: {
                          value: _vm.filters.dongleIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "dongleIds", $$v)
                          },
                          expression: "filters.dongleIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs2: "" } },
                    [
                      _c("date-picker", {
                        attrs: { label: _vm.$t("label.dateFrom") },
                        model: {
                          value: _vm.filters.dateFrom,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "dateFrom", $$v)
                          },
                          expression: "filters.dateFrom",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs2: "" } },
                    [
                      _c("date-picker", {
                        attrs: { label: _vm.$t("label.dateTo") },
                        model: {
                          value: _vm.filters.dateTo,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "dateTo", $$v)
                          },
                          expression: "filters.dateTo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs2: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.refreshList()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("action.filter")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "elevation-1",
                staticStyle: { width: "100%" },
                attrs: {
                  headers: [
                    { text: _vm.$t("label.event"), value: "event" },
                    { text: _vm.$t("label.timestamp"), value: "ts" },
                    { text: _vm.$t("label.deviceId"), value: "deviceId" },
                    { text: _vm.$t("label.sessionId"), value: "sessionId" },
                    { text: _vm.$t("label.data"), value: "data" },
                  ],
                  items: _vm.metrics,
                  pagination: _vm.pagination,
                  "rows-per-page-text": _vm.$t("label.rowsPerPage"),
                  "no-data-text": _vm.$t("label.noData"),
                  "no-results-text": _vm.$t("label.noResult"),
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "pageText",
                    fn: function (props) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("label.pagination", props)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(props.item.event))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.ts))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.deviceId))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.sessionId))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.data))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }