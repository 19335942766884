import MutationTypes from '@/stores/MutationTypes';

const PrefsModule = {
  state: {
    locale: 'fr',
  },

  mutations: {
    [MutationTypes.SET_LOCALE](state, newLocale) {
      state.locale = newLocale;
    },
  },
};

export default PrefsModule;
