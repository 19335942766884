<template>
  <arcadia-ui :sidebar="false">
    <v-content>
      <v-container fluid>
        <v-form v-model="valid">
          <v-autocomplete
            v-model="project.gitlabProject"
            :no-data-text="$t('error.noData')"
            :items="gitlabProjects"
            :loading="gitlabProjects.length == 0"
            item-text="name"
            item-value="id"
            :label="$t('label.gitlabProject')"
            dense
            return-object
          />

          <v-text-field
            v-model="project.description"
            :rules="[
              v => !!v || $t('error.descriptionRequired'),
              v => v.length >= 4 || $t('error.descriptionLength')
            ]"
            :label="$t('label.description')"
          />

          <v-btn @click="save" :disabled="!valid">
            {{$t('action.create')}}
          </v-btn>
        </v-form>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaBackend from '@/externals/ArcadiaBackend';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  data: () => ({
    project: {
      gitlabProject: null,
      description: '',
    },
    valid: true,
  }),
  computed: {
    gitlabProjects() {
      const projects = AppStore.state.data.gitlabProjects[1] || [];
      return projects.map(p => ({ name: p.name, id: p.id, avatar: p.avatar_url }));
    },
  },
  methods: {
    save() {
      var obj = {
        gitlabProjectId: this.project.gitlabProject.id,
        avatar: this.project.gitlabProject.avatar,
        description: this.project.description,
      };
      ArcadiaBackend.Project.createProject(obj)
        .then(p => { router.push(`/projects/${p.name}`); });
    },
  },
  created() {
    AppStore.dispatch(ActionTypes.LOAD_GL_PROJECTS, {
      maxPages: 1,
      perPage: 100,
      page: 1,
      showPagination: true,
    });
  },
  components: { ArcadiaUi },
};
</script>
