<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menu"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <v-text-field
      slot="activator"
      v-model="date"
      :label="label"
      prepend-icon="event"
      readonly
    />

    <v-date-picker
      v-model="date"
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  props: {
    value: { type: String, required: true },
    label: { type: String, default: '' },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); },
    },
  },
};
</script>
