<template>
  <arcadia-ui :sidebar="false" :topbar="false">
    <v-content>
      <h1>{{$t('title.app')}}</h1>
      <v-btn @click="login" color="primary">
        <v-icon left dark>lock</v-icon>
        {{$t('action.loginGitlab')}}
      </v-btn>
      <v-checkbox :label="$t('label.stayConnected')" v-model="keepalive" />
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import MutationTypes from '@/stores/MutationTypes';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  data: () => ({
    keepalive: true,
  }),
  methods: {
    login() {
      AppStore.commit(MutationTypes.SET_KEEPALIVE, this.keepalive);
      router.push('/gitlab-oauth');
    },
  },
  components: { ArcadiaUi },
};
</script>

<style>
.login-screen h1 {
  margin: 0;
  margin-bottom: 24px;
  margin-top: calc(-24px - 1.5em);
}

.login-screen .v-content {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.login-screen .v-content__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
