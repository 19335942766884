import axios from 'axios';
import session from '@/session';
import appConfig from '@/appConfig';
import Bluebird from 'bluebird';
import _ from 'lodash';
import errorHandling from './ArcadiaBackendErrorHandling';

const exports = {};
export default exports;

// -- Projects ------
exports.Project = {};
let getAllProjectsPromise;

/** @returns {Promise<Project[]>} */
exports.Project.getAllProjects = () => {
  getAllProjectsPromise = sendApiRequest('GET', '/get-all-projects');
  return getAllProjectsPromise;
};

// -- ISOs ------
exports.ISO = {};
let getAllISOsPromise;

/** @returns {Promise<ISO[]>} */
exports.ISO.getAllISOs = () => {
  getAllISOsPromise = sendApiRequest('GET', '/get-all-isos');
  return getAllISOsPromise;
};

/** @returns {Promise<Project[]>} */
exports.Project.getAllArchivedProjects = () => {
  getAllProjectsPromise = sendApiRequest('GET', '/get-all-archived-projects');
  return getAllProjectsPromise;
};

/** @returns {Promise<Project>} */
exports.Project.getProjectByName = (projectName) => {
  return sendApiRequest('GET', '/get-project-by-name', {
    query: { projectName },
  });
};

/** @returns {Promise<Project>} */
exports.Project.createProject = (params) => {
  return sendApiRequest('POST', '/create-project', {
    body: omitUnsetFields(params),
  });
};

/** @returns {Promise<Project>} */
exports.Project.updateProject = (projectName, params) => {
  return sendApiRequest('POST', '/update-project', {
    query: { projectName },
    body: omitUnsetFields(params),
  });
};

// -- Builds ------
exports.Build = {};

/** @returns {Promise<Build[]>} */
exports.Build.getAllBuilds = (projectName, buildType) => {
  return sendApiRequest('GET', '/get-all-builds', {
    query: { projectName, buildType },
  });
};

/** @returns {Promise<void>} */
exports.Build.getBuildDownloadUrl = (projectName, version) => {
  return sendApiRequest('GET', '/get-download-build-url',
    { query: { projectName, version } });
};

/** @returns {Promise<void>} */
exports.Build.makeBuildPermanent = (projectName, version) => {
  return sendApiRequest('PUT', '/make-build-permanent', {
    query: { projectName, version },
  });
};

// -- Devices ------
exports.Device = {};

/** @returns {Promise<Device[]>} */
exports.Device.getAllDevices = (projectName) => {
  return sendApiRequest('GET', '/get-all-devices', {
    query: { projectName },
  });
};

/** @returns {Promise<void>} */
exports.Device.updateDevice = (projectName, deviceId, update) => {
  return sendApiRequest('PUT', '/update-device', {
    query: { projectName, deviceId },
    body: omitUnsetFields(update),
  });
};

/** @returns {Promise<void>} */
exports.Device.deleteDevice = (projectName, deviceId) => {
  return sendApiRequest('PUT', '/delete-device', {
    query: { projectName, deviceId },
  });
};

// -- Groups ------
exports.Group = {};

/** @returns {Promise<Group[]>} */
exports.Group.getAllGroups = (projectName) => {
  return sendApiRequest('GET', '/get-all-groups', {
    query: { projectName },
  });
};

/** @returns {Promise<Group>} */
exports.Group.createGroup = (projectName, params) => {
  return sendApiRequest('POST', '/create-group', {
    query: { projectName },
    body: omitUnsetFields(params),
  });
};

/** @returns {Promise<void>} */
exports.Group.setGroupDeployConfig = (projectName, groupId, deployConfig) => {
  return sendApiRequest('PUT', '/set-group-deploy-configuration', {
    query: { projectName, groupId },
    body: { _id: groupId, ...deployConfig },
  });
};

/** @returns {Promise<void>} */
exports.Group.updateGroup = (projectName, groupId, groupName, accessRight) => {
  return sendApiRequest('PUT', '/set-group-deploy-configuration', {
    query: { projectName, groupId },
    body: { _id: groupId, name: groupName, accessRight: accessRight },
  });
};

/** @returns {Promise<void>} */
exports.Group.setGroupConfig = (projectName, groupId, config) => {
  return sendApiRequest('PUT', '/update-group', {
    query: { projectName, groupId },
    body: omitUnsetFields(config),
  });
};

/** @returns {Promise<void>} */
exports.Group.deleteGroup = (projectName, groupId) => {
  return sendApiRequest('POST', '/delete-group', {
    query: { projectName, groupId },
  });
};

// -- Metrics ------
exports.Metric = {};

/**
 * @param {Object} query
 * @param {Object} paging ({ resultPerPage, currentPage })
 * @returns {Promise<Metric[]>}
 */
exports.Metric.getMetrics = (projectName, query, paging) => {
  return sendApiRequest('POST', '/get-metrics', {
    query: { projectName },
    body: Object.assign({}, query, paging),
  });
};

// -- Logs ------
exports.Logs = {};

/**
 * @param {Object} query
 * @returns {Promise<Logs[]>}
 */
exports.Logs.getLogs = () => {
  return sendApiRequest('POST', '/get-all-logs');
};

// -- Auth ------
/** @returns {Promise<SarbaCreds>} */
exports.login = ({ gitlabToken, projectId }) => {
  return sendApiRequest('POST', '/login', {
    auth: false,
    body: { gitlabToken, projectId },
  });
};

/** @returns {Promise<SarbaCreds>} */
exports.renewToken = () => {
  return sendApiRequest('POST', '/renew-token');
};

// -- plumbing ------
function sendApiRequest(method, path, params) {
  const url = appConfig.analyticsBackend.baseUrl + path;
  const { query, auth, body } = params || {};

  // add auth token to headers
  const headers = Object.assign({}, params && params.headers);
  if (auth !== false && !headers['Authorization']) {
    const token = session.getBackendToken();
    if (token) headers['Authorization'] = `Bearer ${token}`;
  }

  if (body == null) headers['Content-Type'] = 'text/plain';

  // send request
  return Bluebird.resolve()
    .then(() => axios({ method, url, params: query, headers, data: body }))
    .then((res) => {
      return res.data;
    })
    .catch(err => _.get(err.response, 'status') >= 400, (err) => {
      const res = err.response;
      const handler = errorHandling.findErrorHandler(res);
      const result = handler && handler(res);
      if (result !== undefined) return result;
      throw res;
    });
};

function omitUnsetFields(obj) {
  return _.omitBy(obj, v => v == null || v === '');
}
