var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.screenName, "screen"] },
    [
      _vm.topbar && _vm.isAuthenticated ? _c("arcadia-topbar") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "below-topbar" },
        [
          _vm.sidebar && _vm.isAuthenticated
            ? _c("arcadia-sidebar", {
                attrs: { "project-name": _vm.projectName },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "screen-content" }, [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }