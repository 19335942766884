<template>
  <arcadia-ui :sidebar="false">
    <v-content>
      <v-container fluid>
        <v-data-table
          :headers="[
            { sortable: false },
            { text: $t('label.id'), value: 'id' },
            { text: $t('label.name'), value: 'name' },
            { text: $t('label.creationDate'), value: 'created_at' },
            { text: $t('label.lastUpdate'), value: 'updated_at' },
            { sortable: false },
          ]"
          :items="currentPageItems"
          :pagination.sync="pagination"
          :total-items="totalItems"
          :loading="loading"
          :rows-per-page-text="$t('label.rowsPerPage')"
          :no-data-text="$t('label.noData')"
          :no-results-text="$t('label.noResult')"
          class="elevation-1" style="width: 100%"
        >
          <template slot="pageText" slot-scope="props">
            {{$t('label.pagination', props)}}
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                <img class="project-avatar" :src="props.item.avatar_url" />
              </td>
              <td>{{props.item.id}}</td>
              <td>{{props.item.name}}</td>
              <td>{{props.item.created_at}}</td>
              <td>{{props.item.updated_at}}</td>
              <td>
                <a class="clickable" target="_blank" :href="props.item.web_url">
                  <v-icon>open_in_new</v-icon>
                </a>
              </td>
            </tr>
          </template>
        </v-data-table>

      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  data: () => ({
    pagination: {
      sortBy: 'id',
      descending: true,
      rowsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
  }),
  computed: {
    currentPageItems() {
      return AppStore.state.data.gitlabProjects[this.pagination.page] || [];
    },
    loading: () => AppStore.state.data.isLoading,
  },
  watch: {
    pagination: {
      handler(newValue) {
        const { sortBy, descending, page, rowsPerPage } = newValue;
        const query = {
          maxPages: 1,
          perPage: rowsPerPage,
          page: page,
          showPagination: true,
          sort: descending ? 'desc' : 'asc',
          order_by: sortBy,
        };

        AppStore.dispatch(ActionTypes.LOAD_GL_PROJECTS, query)
          .then(() => { this.totalItems = AppStore.state.data.gitlabProjectsCount; });
      },
      deep: true,
    },
  },
  components: { ArcadiaUi },
};
</script>

<style>
.gitlab-project-list-screen .project-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
</style>
