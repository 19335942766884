<style>
  img { border-radius: 50%; }
</style>
<template>
  <arcadia-ui :sidebar="false">
    <v-content>
      <v-container fluid>
        <v-data-table
          :headers="[
            { text: $t('label.name'), value: 'name' },
          ]"
          :items="projects"
          :hide-actions="true"
          :no-data-text="$t('label.noData')"
          :no-results-text="$t('label.noResult')"
          class="elevation-1" style="width: 100%">
          <template slot="items" slot-scope="props">
            <tr class="clickable" @click="selectProject(props.item)">
              <td><img width="30px" height="30px" :src="props.item.avatar || null" /></td>
              <td text-align="left">{{props.item.name}}</td>
              <td>{{props.item.gitlabProjectId}}</td>
            </tr>
          </template>
        </v-data-table>

      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaBackend from '@/externals/ArcadiaBackend';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import session from '@/session.js';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  data: () => ({
    projects: [],
  }),
  methods: {
    loadData() {
      ArcadiaBackend.Project.getAllProjects()
        .then(body => { this.projects = body; });
    },
    selectProject(project) {
      const gitlabToken = session.getGitlabToken();
      const id = project.gitlabProjectId;
      AppStore.dispatch(ActionTypes.LOGIN, { gitlabToken, projectId: id })
        .then(() => {
          AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, project);
          router.push(`/projects/${project.name}/devices`);
        });
    },
  },
  created() {
    this.loadData();
  },
  components: { ArcadiaUi },
};
</script>
