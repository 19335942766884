import { ProjectsBundle } from 'axios-gitlab';
import AppStore from '@/stores/AppStore';
import appConfig from '@/appConfig';

const exports = {};
export default exports;

exports.getAllProjects = query => getClient().Projects.all(query);
exports.getProjectById = id => getClient().Projects.show(id);

exports.accessRights = [
  { label: 'Developper', value: 30 },
  { label: 'Maintainer', value: 40 },
  { label: 'Owner', value: 50 },
];

const getClient = () => new ProjectsBundle({
  url: appConfig.gitlab.baseUrl,
  oauthToken: AppStore.state.auth.gitlabToken || 'NONE',
});
