var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("h2", [_vm._v("Device " + _vm._s(_vm.device.dongleId))]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: { label: _vm.$t("label.name") },
                model: {
                  value: _vm.device.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.device, "name", $$v)
                  },
                  expression: "device.name",
                },
              }),
              _vm._v(" "),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.availableGroups,
                  "item-text": "label",
                  "item-value": "value",
                  label: _vm.$t("label.group"),
                  dense: "",
                },
                model: {
                  value: _vm.device.groupId,
                  callback: function ($$v) {
                    _vm.$set(_vm.device, "groupId", $$v)
                  },
                  expression: "device.groupId",
                },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "green" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("action.save")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "red" },
                  on: {
                    click: function ($event) {
                      return _vm.remove()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("action.remove")))]
              ),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "290" },
                  model: {
                    value: _vm.deleteDialog,
                    callback: function ($$v) {
                      _vm.deleteDialog = $$v
                    },
                    expression: "deleteDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.$t("title.confirm"))),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _vm._v(_vm._s(_vm.$t("message.confirmDeleteDevice"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("div", { staticClass: "flex-grow-1" }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.yes")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.denyDelete()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.no")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }