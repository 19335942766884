<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>
        <v-btn :href="`/projects/${this.projectName}/groups/new`">
          {{$t('action.create')}}
        </v-btn>
        <v-data-table
          :headers="[
            { text: $t('label.name'), value: 'name' },
            { text: $t('label.targetBuild'), value: 'targetBuild' },
            { text: $t('label.parent'), value: 'parent' },
            { text: $t('label.config'), value: 'config' },
            { text: $t('label.nbDevice'), value: 'config' },
          ]"
          :items="groups"
          :hide-actions="true"
          :loading="loading"
          :rows-per-page-text="$t('label.rowsPerPage')"
          :no-data-text="$t('label.noData')"
          :no-results-text="$t('label.noResult')"
          class="elevation-1 wide-table">
          <template slot="pageText" slot-scope="props">
            {{$t('label.pagination', props)}}
          </template>
          <template slot="items" slot-scope="props">
            <tr class="clickable" slot="activator"
              @click="gotoGroupDetails(props.item.name)">
              <td>{{props.item.name}}</td>
              <td>{{props.item.targetBuild}}</td>
              <td>{{getGroupName(props.item.parent)}}</td>
              <td>{{JSON.stringify(props.item.configs, null, 1)}}</td>
              <td>{{getNumberOfDevices(props.item._id) + " " + $t('label.devicesInGroup')}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
  },
  computed: {
    groups: () => AppStore.state.data.projectGroups || [],
    loading: () => AppStore.state.data.isLoading,
    devices: () => AppStore.state.data.projectDevices || [],
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName);
      AppStore.dispatch(ActionTypes.LOAD_GROUPS, { refresh: true });
      AppStore.dispatch(ActionTypes.LOAD_DEVICES);
    },
    getGroupName(id) {
      const parent = this.groups.find(g => g._id === id);
      if (parent == null) return id;
      return parent.name;
    },
    gotoGroupDetails(groupName) {
      router.push(`/projects/${this.projectName}/groups/${groupName}`);
    },
    getNumberOfDevices(groupName) {
      const count = this.devices.filter(d => groupName === d.groupId).length;
      return count;
    },
  },
  watch: {
    projectName: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
  },
  mounted: function() {
    AppStore.dispatch(ActionTypes.LOAD_GROUPS, { refresh: true });
  },
  components: { ArcadiaUi },
};
</script>

<style>
.group-list-screen .details-link:hover {
  color: blue;
}
.group-list-screen .wide-table {
  width: 100%;
}
</style>
