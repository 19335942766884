<template>
  <div :class="[screenName, 'screen']">
    <arcadia-topbar v-if="topbar && isAuthenticated" />
    <div class="below-topbar">
      <arcadia-sidebar v-if="sidebar && isAuthenticated"
        :project-name="projectName" />
      <div class="screen-content"><slot /></div>
    </div>
  </div>
</template>

<script>
import session from '@/session';
import ArcadiaTopbar from './ArcadiaTopbar';
import ArcadiaSidebar from './ArcadiaSidebar';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  props: {
    screenName: {
      type: String,
      default() { return vueToScreenName(this.$parent) || 'nameless-screen'; },
    },
    topbar: { type: Boolean, default: true },
    sidebar: { type: Boolean, required: true },
  },
  computed: {
    projectName: () => router.currentRoute.params.projectName,
    isAuthenticated: () => session.isAuthenticated(),
  },
  components: { ArcadiaTopbar, ArcadiaSidebar },
};

function vueToScreenName(vue) {
  if (!vue || !vue.$options.name) return null;
  return vue.$options.name.replace(/-screen$/, '') + '-screen';
}
</script>
