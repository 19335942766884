<template>
  <arcadia-ui :sidebar="false">
    <v-content>
      <h2>Not Found</h2>
      <h3>The requested url doesn't exist</h3>
    </v-content>
  </arcadia-ui>
</template>

<script>
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { filepathToComponentName } from '@/utils/utils';

export default {
  name: filepathToComponentName(__filename),
  components: { ArcadiaUi },
};
</script>

<style>
.not-found-screen .v-content {
  display: flex;
  height: 100vh;
  justify-content: center;
}

.not-found-screen .v-content__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4em;
}
</style>
