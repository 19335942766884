<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>
        <v-data-table style="width: 100%"
          :headers="[
            { text: $t('label.id'), value: '_id' },
            { text: $t('label.name'), value: 'name' },
            { text: $t('label.group'), value: 'groupName' },
            { text: $t('label.lastUpdate'), value: 'lastUpdate' },
          ]"
          :items="devices"
          :hide-actions="true"
          :loading="loading()"
          :rows-per-page-text="$t('label.rowsPerPage')"
          :no-data-text="$t('label.noData')"
          :no-results-text="$t('label.noResult')"
          class="elevation-1">
          <template slot="pageText" slot-scope="props">
            {{$t('label.pagination', props)}}
          </template>
          <template slot="items" slot-scope="props">
            <tr class="clickable" slot="activator" @click="gotoDeviceDetails(props.item.dongleId)">
              <td>{{props.item.dongleId}}</td>
              <td>{{props.item.name}}</td>
              <td>{{props.item.groupName}}</td>
              <td>{{props.item.lastUpdateText}}</td>

              <td>
                <v-tooltip top>
                  <span>
                    <v-icon>description</v-icon>
                  </span>
                  <span>{{$t('action.edit', props)}}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';
import i18n from '@/i18n';

import { formatDistance, subDays, isAfter } from 'date-fns';
import { fr } from 'date-fns/locale';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
  },
  computed: {
    devices: () => AppStore.state.data.projectDevices || [],
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName);
      AppStore.dispatch(ActionTypes.LOAD_DEVICES);
      AppStore.dispatch(ActionTypes.LOAD_GROUPS);
    },
    gotoDeviceDetails(dongleId) {
      router.push(`/projects/${this.projectName}/devices/${dongleId}`);
    },
    loading: () => AppStore.state.data.isLoading,
  },
  watch: {
    projectName: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
    devices: {
      deep: true,
    },
  },
  created: function() {
    const now = Date.now();
    const dayBefore = subDays(new Date(), 1);
    AppStore.dispatch(ActionTypes.LOAD_DEVICES, { refresh: true })
      .then(() => {
        AppStore.state.data.projectDevices.forEach(function(device) {
          const group = (AppStore.state.data.projectGroups || [])
            .find(g => g._id === device.groupId);
          device.groupName = (group ? group.name : null);

          const lastUpdateDate = new Date(device.lastUpdate);
          const fmtDistanceOpts = { addSuffix: true };
          if (i18n.locale === 'fr') {
            fmtDistanceOpts.locale = fr;
          }
          const isOnline = isAfter(lastUpdateDate, dayBefore) ? '✅' : '❌';
          const dateDistance = (device.lastUpdate ? formatDistance(lastUpdateDate, now, fmtDistanceOpts) : i18n.t('error.noData'));
          device.lastUpdateText = `${isOnline} ${dateDistance}`;
        });
      });
  },
  components: { ArcadiaUi },
};
</script>
