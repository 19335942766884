<template>
  <v-app id="vue-root" class="app">
    <router-view />
  </v-app>
</template>

<script>
export default { name: 'app' };
</script>

<style>
#vue-root {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.clickable {
  cursor: pointer;
  text-decoration: none;
}
</style>
