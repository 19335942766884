var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "arcadia-ui",
    { attrs: { sidebar: true } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                staticStyle: { width: "100%" },
                attrs: {
                  headers: [
                    { text: _vm.$t("label.version"), value: "version" },
                    { text: _vm.$t("label.date"), value: "modified" },
                    { text: _vm.$t("label.filename"), value: "tags.filename" },
                    { text: _vm.$t("label.branch"), value: "tags.ref" },
                    { text: _vm.$t("label.job"), value: "tags.job" },
                    { text: _vm.$t("label.size"), value: "size" },
                  ],
                  items: _vm.builds,
                  pagination: _vm.pagination,
                  loading: _vm.loading,
                  "rows-per-page-text": _vm.$t("label.rowsPerPage"),
                  "no-data-text": _vm.$t("label.noData"),
                  "no-results-text": _vm.$t("label.noResult"),
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "pageText",
                    fn: function (props) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("label.pagination", props)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(props.item.version))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.formatDate(props.item.modified))),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(props.item.tags["X-Amz-Meta-Filename"])
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(props.item.tags["X-Amz-Meta-Ref"])),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(props.item.tags["X-Amz-Meta-Job"])),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                Math.round(
                                  (props.item.size / (1000 * 1000)) * 100
                                ) / 100
                              ) + " MB"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("v-tooltip", { attrs: { top: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "clickable",
                                    attrs: { slot: "activator" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadBuild(
                                          props.item.version
                                        )
                                      },
                                    },
                                    slot: "activator",
                                  },
                                  [
                                    _c("v-icon", { attrs: { medium: "" } }, [
                                      _vm._v("cloud_download"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("action.downloadBuild", props)
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              props.item.buildType !== "live"
                                ? _c("v-tooltip", { attrs: { top: "" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "clickable",
                                        attrs: { slot: "activator" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.makeBuildPermanent(
                                              props.item.version
                                            )
                                          },
                                        },
                                        slot: "activator",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { medium: "" } },
                                          [_vm._v("cloud_upload")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("action.uploadLive", props)
                                        )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              props.item.buildType === "live"
                                ? _c("v-tooltip", { attrs: { top: "" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "transparent",
                                        attrs: { slot: "activator" },
                                        slot: "activator",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { medium: "" } },
                                          [_vm._v("cloud_done")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("action.uploaded", props))
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.buildIsUsed(props.item.version)
                                ? _c("v-tooltip", { attrs: { top: "" } }, [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "activator" },
                                        slot: "activator",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { medium: "" } },
                                          [_vm._v("all_inclusive")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("action.used", props))
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }