<template>
  <arcadia-ui :sidebar="true">
    <v-content>
      <v-container fluid>
        <v-form v-model="valid">
          <v-text-field
            v-model="group.name"
            :rules="[
              v => !!v || $t('error.nameRequired'),
              v => v.length >= 4 || $t('error.nameLength')
            ]"
            :label="$t('label.name')"
          />

          <v-autocomplete
            v-model="group.parent"
            :no-data-text="$t('error.noData')"
            :items="availableGroups"
            item-text="label"
            item-value="value"
            :label="$t('label.parent')"
            dense
          />

          <v-autocomplete
            v-model="group.accessRight"
            :rules="[
              v => !!v || $t('error.rightRequired')
            ]"
            :items="availableRights"
            item-text="label"
            item-value="value"
            :label="$t('label.access')" />

          <v-btn :disabled="!valid" @click="save">
            {{$t('action.create')}}
          </v-btn>
        </v-form>
      </v-container>
    </v-content>
  </arcadia-ui>
</template>

<script>
import AppStore from '@/stores/AppStore';
import ActionTypes from '@/stores/ActionTypes';
import ArcadiaBackend from '@/externals/ArcadiaBackend';
import SarbaGitlab from '@/externals/SarbaGitlab';
import ArcadiaUi from '@/_components/_core/ArcadiaUi';
import { router } from '@/_routing';
import { filepathToComponentName } from '@/utils/utils';
import _ from 'lodash';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
  },
  data: () => ({
    group: {
      name: '',
      parent: null,
      accessRight: null,
      config: {
        metricLogLevel: 4,
      },
    },
    valid: true,
  }),
  computed: {
    availableGroups() {
      return (AppStore.state.data.projectGroups || [])
        .map(g => ({ label: g.name, value: g._id }));
    },
    availableRights() {
      return SarbaGitlab.accessRights;
    },
  },
  methods: {
    loadProjectData() {
      AppStore.dispatch(ActionTypes.SET_ACTIVE_PROJECT, this.projectName);
      AppStore.dispatch(ActionTypes.LOAD_GROUPS);
    },
    save() {
      ArcadiaBackend.Group.createGroup(this.projectName, _.cloneDeep(this.group))
        .then(() => { AppStore.dispatch(ActionTypes.UNLOAD_GROUPS); })
        .then(() => { router.go(-1); });
    },
  },
  watch: {
    projectName: {
      handler() { this.loadProjectData(); },
      immediate: true,
    },
  },
  components: { ArcadiaUi },
};
</script>
