<template>
  <v-navigation-drawer app class="project-nav" :mini-variant="isCollapsed"
    width="240" mini-variant-width="72" clipped permanent>
    <h2 class="project-title">
      <img v-if="gitlabProject" class="icon" :src="gitlabProject.avatar_url" />
      <span v-if="!isCollapsed" class="label">{{projectName}}</span>
    </h2>

    <div class="project-nav">
      <v-btn v-for="navItem in projectNavItems" :key="navItem.route.name"
        :href="navItem.route | routeToHref({ projectName })"
        @click.prevent="navigateWithoutReload"
        :class="{active: navItem.route === activeRoute}" flat>
        <v-icon class="icon">{{navItem.icon}}</v-icon>
        <span v-if="!isCollapsed" class="label">{{navItem.label}}</span>
      </v-btn>
    </div>

    <v-btn class="collapse-toggle-btn" @click="confirmArchiveProject()" flat>
      <v-icon class="icon">{{ archived? 'unarchive' : 'archive'}}</v-icon>
      <span v-if="!isCollapsed" class="label">{{archived? $t('action.restore') : $t('action.archive')}}</span>
    </v-btn>

    <v-btn class="collapse-toggle-btn" @click="isCollapsed = !isCollapsed" flat>
      <v-icon class="icon">{{isCollapsed ? 'chevron_right' : 'chevron_left'}}</v-icon>
      <span v-if="!isCollapsed" class="label">{{$t('action.collapseSidebar')}}</span>
    </v-btn>

    <v-dialog v-model="archiveDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{
          $t("title.confirm")
        }}</v-card-title>
        <v-card-text>{{ $t("message.confirmArchive") }}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn text @click="archiveProject()">{{ $t("label.yes") }}</v-btn>
          <v-btn text @click="denyArchiveProject()">{{ $t("label.no") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import AppStore from '@/stores/AppStore';
import i18n from '@/i18n';
import { router, routes, getRoute } from '@/_routing';
import { filepathToComponentName, clickEventToHref } from '@/utils/utils';
import ArcadiaBackend from '@/externals/ArcadiaBackend';

export default {
  name: filepathToComponentName(__filename),
  props: {
    projectName: { type: String, required: true },
  },
  data: () => ({
    archiveDialog: false,
    projectNavItems: [
      {
        label: i18n.t('title.projectDevices'),
        route: routes.byPath['/projects/:projectName/devices'],
        icon: 'devices',
      },
      {
        label: i18n.t('title.projectBuilds'),
        route: routes.byPath['/projects/:projectName/builds'],
        icon: 'backup',
      },
      {
        label: i18n.t('title.projectDetails'),
        route: routes.byPath['/projects/:projectName'],
        icon: 'home',
      },
      {
        label: i18n.t('title.projectGroups'),
        route: routes.byPath['/projects/:projectName/groups'],
        icon: 'group',
      },
      {
        label: i18n.t('title.projectMetrics'),
        route: routes.byPath['/projects/:projectName/metrics'],
        icon: 'insert_chart',
      },
    ].map(v => Object.assign(v, { key: v.route.name })),
    isCollapsed: false,
  }),
  computed: {
    gitlabProject() {
      return AppStore.state.data.gitlabProject || {};
    },
    activeRoute() {
      return getRoute(router.currentRoute);
    },
    archived() {
      return AppStore.state.data.project ? AppStore.state.data.project.archive : false;
    },
  },
  methods: {
    confirmArchiveProject() {
      this.archiveDialog = true;
    },
    archiveProject() {
      var archive = AppStore.state.data.project ? AppStore.state.data.project.archive : false;
      ArcadiaBackend.Project.updateProject(AppStore.state.data.projectName, { archive: !archive });
      AppStore.state.data.project.archive = !archive;
      this.archiveDialog = false;
    },
    denyArchiveProject() {
      this.archiveDialog = false;
    },
    navigateWithoutReload: event => router.push(clickEventToHref(event)),
  },
};
</script>

<style>
.project-title {
  font-size: inherit;
  font-weight: normal;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  overflow: hidden;
  white-space: nowrap;
}

.project-title .icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.project-title .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-nav {
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}

.project-nav .v-btn--flat.active {
  background: #eee;
}

.project-nav .v-btn--flat {
  display: flex;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 12px 16px;
  text-transform: none;
}

.project-nav .v-btn--flat .v-btn__content {
  justify-content: left;
}
.project-nav .v-btn--flat .icon {
  padding: 0 8px;
  margin: 0;
  margin-right: 12px;
  color: #777 !important;
}

.project-nav .v-btn--flat .label {
  margin-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  color: #333 !important;
}

@media (min-width: 960px) {
  .project-title {
    padding: 12px 16px;
  }
}
</style>
