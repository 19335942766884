var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        lazy: "",
        transition: "scale-transition",
        "offset-y": "",
        "full-width": "",
        "min-width": "290px",
      },
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c("v-text-field", {
        attrs: {
          slot: "activator",
          label: _vm.label,
          "prepend-icon": "event",
          readonly: "",
        },
        slot: "activator",
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
      _vm._v(" "),
      _c("v-date-picker", {
        on: {
          input: function ($event) {
            _vm.menu = false
          },
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }